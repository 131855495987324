import React, {useState} from "react";
import rocket from '@images/rocket.svg'
import arrow_drop_down from '@images/arrow_drop_down.svg'
import Description from "./Description";

const features_free = [I18n.t("plans.standard.bullet1"), I18n.t("plans.standard.bullet2"), I18n.t("plans.standard.bullet3"), I18n.t("plans.standard.bullet4"), I18n.t("plans.standard.bullet5")]

const Standart = ({standart, setStandart}) => {
  const [showDeatils, setShowDeatils] = useState(false);
  const handleClick = () => {
    setShowDeatils(!showDeatils);
    setStandart(!standart)
  }

  return (
    <div className='standart-block' onClick={handleClick}>

      <div className='price-header'>
        <img className='price-header__img' width='48' height='48' src={rocket} alt="rocket"/>
        <div className='price-header__txt standart'>
          <h3 className='standart__name'>Standart</h3>
          <p className='standart__price'>0 ₽ / { I18n.t("plans.forever") }</p>
        </div>
        <img className='price-header__arrow_drop_down' width='9' height='7' src={arrow_drop_down} alt="arrow_drop_down"/>
      </div>

      <Description showDeatils={showDeatils} features={features_free}/>

    </div>
  )
}


export default Standart;
