import React, { useState } from 'react';
import classNames from 'classnames';
import InlineSvg from '@components/InlineSvg';

const Link = ({href, text, icon, icon_name, react_icon, className, method, target, iconClassName, onClick, active, changeActive}) => {
  const pathname = window.location.pathname;

  const handleClick = (e) => {

    if(onClick){
      e.preventDefault()
      
      if(changeActive){
        if(active == false){
          changeActive(href)
        }else{
          changeActive(pathname)
        }
      }
      

      onClick()
    }
  }

  return (
    <a href={href} target={target && "_blank"} data-method={ method || null} className={classNames(className, active ? "active" : null)} onClick={(e) => handleClick(e)}>
      {icon && <InlineSvg className={iconClassName} src={icon} src_name={icon_name} react_svg={react_icon}/>}
      <div className='text-xs'>{text}</div>
    </a>
  )
}

export default Link;
