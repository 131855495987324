import React from 'react';

export default props => {
  return (
    <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="63" height="63" rx="15" fill="#7E57C2" fillOpacity="0.3"/>
      <path d="M31.4925 25.1665C30.2439 25.168 29.0237 25.5384 27.9851 26.2314C26.9465 26.9243 26.1359 27.9087 25.6551 29.061C25.1743 30.2132 25.0448 31.4819 25.2829 32.7075C25.521 33.9332 26.116 35.0611 26.9932 35.9496C27.8704 36.838 28.9907 37.4474 30.2131 37.701C31.4356 37.9547 32.7058 37.8414 33.8641 37.3753C35.0223 36.9092 36.017 36.1111 36.7231 35.0814C37.4291 34.0517 37.8151 32.8362 37.8324 31.5878C37.8468 30.7474 37.6933 29.9126 37.3808 29.1324C37.0683 28.3521 36.6032 27.6421 36.0126 27.044C35.4221 26.4459 34.7181 25.9717 33.9419 25.6493C33.1657 25.3269 32.3329 25.1628 31.4925 25.1665ZM31.4451 35.6814C30.4097 35.6465 29.4279 35.2123 28.7053 34.4699C27.9827 33.7275 27.5753 32.7344 27.5683 31.6984C27.5613 30.6624 27.9553 29.6638 28.6678 28.9117C29.3803 28.1596 30.3561 27.7122 31.391 27.6633C31.931 27.6378 32.4705 27.7216 32.9773 27.9098C33.4841 28.098 33.9476 28.3867 34.3401 28.7585C34.7325 29.1303 35.0458 29.5775 35.261 30.0734C35.4763 30.5693 35.5891 31.1036 35.5928 31.6442C35.5964 32.1848 35.4908 32.7205 35.2823 33.2193C35.0737 33.718 34.7665 34.1695 34.3791 34.5466C33.9917 34.9236 33.5322 35.2185 33.028 35.4135C32.5238 35.6086 31.9854 35.6997 31.4451 35.6814Z" fill="#7E57C2"/>
      <path d="M49.4682 29.3902C48.2101 27.814 46.787 26.3792 45.2236 25.1108C37.9186 19.4667 30.084 18.0798 21.6829 22.5224C18.4569 24.1514 15.6454 26.5062 13.4657 29.405C12.2151 31.1182 12.1416 31.8673 13.4657 33.5064C14.5085 34.872 15.6813 36.1314 16.9674 37.2666C21.0655 40.9 26.2984 42.9683 31.7539 43.1109L33.056 43.0293C34.4876 42.838 35.9037 42.543 37.2933 42.1467C42.1446 40.6657 46.4114 37.6846 49.4903 33.6251C50.8145 31.9044 50.866 31.0737 49.4682 29.3902ZM47.7983 32.075C44.1936 36.6658 39.6106 39.6992 33.821 40.5818C27.6196 41.5385 22.4774 39.0169 17.909 35.0639C16.9393 34.1305 16.0255 33.1399 15.1724 32.0972C15.0904 32.0276 15.0245 31.9407 14.9792 31.8427C14.934 31.7447 14.9106 31.638 14.9106 31.5299C14.9106 31.4218 14.934 31.315 14.9792 31.217C15.0245 31.119 15.0904 31.0321 15.1724 30.9625C18.5601 26.4215 23.5426 23.3593 29.0982 22.4037C29.8853 22.2628 30.6872 22.2405 31.2021 22.1886C35.8717 22.2735 40.3683 23.9847 43.9288 27.0317C45.2742 28.2472 46.549 29.5398 47.7468 30.9031C47.83 30.9744 47.8976 31.0623 47.9456 31.1612C47.9936 31.26 48.0209 31.3678 48.0257 31.4778C48.0305 31.5877 48.0128 31.6975 47.9737 31.8002C47.9346 31.903 47.8749 31.9965 47.7983 32.075Z" fill="#7E57C2"/>
    </svg>
  );
};


