import React, {useRef} from "react";
import Router from "@components/router.js";
import checkmark from "@images/checkmark.svg";
import {toast} from "react-toastify";


const Description = ({showDeatils, activeTab, features, paid = false}) => {
  const handleClick = () => {
    const onResponce = (res_data) => {
      if (res_data.success) {
        window.location.replace(res_data.message.formUrl);
      } else {
        toast.error(res_data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    };
    Router.fetchWithData("/payments", {tariff_key: activeTab}, "POST", onResponce);
  }

  const ulRef = useRef(null)
  let styleDescription = {};
  if (showDeatils) {
    styleDescription = {
      paddingTop: '22px',
      paddingBottom: '24px',
      height: ulRef?.current?.offsetHeight || 0
    }
  } else {
    styleDescription = {
      paddingTop: 0,
      paddingBottom: 0,
      height: 0
    }
  }

  return (
    <div className='description' style={styleDescription}>
      <div className='description-block' ref={ulRef}>
        <ul className='description-list'>
          {features.map((value, index) => (
            <li key={index} className='description__item'>
              <img className='description__img' src={checkmark} alt="checkmark"/>
              <p className='description__txt'>
                {value}
              </p>
            </li>
          ))}
        </ul>
        {paid &&
          <button className='description__btn' onClick={handleClick}>{I18n.t('plans.subscribe')}</button>
        }
      </div>
    </div>
  )
}

export default Description;
