import React from "react";

export default (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.7"
        y="3.70562"
        width="8.38699"
        height="8.38699"
        rx="1.3"
        stroke="#92959B"
        strokeWidth="1.4"
      />
      <rect
        x="12.336"
        y="15.3416"
        width="8.38699"
        height="8.38699"
        rx="1.3"
        stroke="#92959B"
        strokeWidth="1.4"
      />
      <rect
        x="11.5777"
        y="6.92041"
        width="8.38699"
        height="8.38699"
        rx="1.3"
        transform="rotate(-45 11.5777 6.92041)"
        stroke="#92959B"
        strokeWidth="1.4"
      />
      <rect
        x="0.7"
        y="15.3416"
        width="8.38699"
        height="8.38699"
        rx="1.3"
        stroke="#92959B"
        strokeWidth="1.4"
      />
    </svg>
  );
};
