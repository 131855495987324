import React, {useEffect, useState} from "react";
import Router from "@components/router.js";
import {toast} from "react-toastify";
import logo_default from "@images/logo_default.svg";

const LogoUpload = ({profile, changeThirdStep, user_paid}) => {
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    let file_size = e.target.files[0].size / 1024;
    let file_type = e.target.files[0].type;

    if ((file_type === 'image/jpeg' || file_type === 'image/png') && file_size < 100) {
      setSelectedFile(e.target.files[0])
    } else {
      toast.error(I18n.t('activemodel.errors.models.profile.attributes.logo.general_error'), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        zIndex: 9999,
      });
    }
  }

  const save = () => {
    const onResponse = (res_data) => {
      if (res_data.success) {
        toast.success(res_data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
        setTimeout(function() {
          window.location.reload()
        }, 2000);
      } else {
        toast.error(res_data.errors, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    };

    if (user_paid) {
      if((selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png') && (selectedFile.size / 1024) < 100) {
        Router.fetchWithData('/profile.json', {'profile[logo]': selectedFile}, 'PATCH', onResponse)
      } else {
        toast.error(I18n.t('activemodel.errors.models.profile.attributes.logo.general_error'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    } else {
      changeThirdStep(false, "go_pro", I18n.t("sub_menu.go_pro_txt.header"))
    }
  }

  const txt = [
    I18n.t("sub_menu.logo_uploads.format"),
    I18n.t("sub_menu.logo_uploads.size"),
    I18n.t("sub_menu.logo_uploads.vol")
  ];

  return (
    <>
      <button className='save_btn' onClick={save}>Сохранить</button>
      <div className='logo'>

        <label
          className='logo__label'
          htmlFor='upload'
        >
          <div className='logo__label-txt'>
            {txt.map(i => <p key={i} className='logo__label-p'>{i}</p>)}
          </div>
          <div className='logo__label-file'>
            <img className='logo__label-img'
                 width='111'
                 height='22'
                 src={selectedFile ? preview : logo_default}
                 alt='logo'/>
          </div>
        </label>

        <input
          className='logo__input'
          type='file'
          id='upload'
          onChange={onSelectFile}
          accept="image/png, image/jpeg"
        />

      </div>
    </>
  )
}

export default LogoUpload;
