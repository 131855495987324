import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']
  connect () {}
  open () {
    if ($("#sub_nav").hasClass("open")){
      $("#sub_nav").addClass("close")
      $("#sub_nav").removeClass("open");
    } else{
      $("#sub_nav").addClass("open")
      $("#sub_nav").removeClass("close")
    }
  }
}
