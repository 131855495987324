import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']
  connect () {}
  open () {
    $("#share_modal").toggle('hidden')
    $("#modal_fader").toggle('hidden')
  }
  close () {
    $("#share_modal").toggle('hidden')
    $("#modal_fader").toggle('hidden')
  }
  open_qr () {
    $("#qr_modal").toggle('hidden')
    $("#modal_fader").toggle('hidden')
  }
  close_qr () {
    $("#qr_modal").toggle('hidden')
    $("#modal_fader").toggle('hidden')
  }
  close_all (){
    if($("#share_modal").css('display') == 'block'){
      $("#share_modal").toggle('hidden')
    }else{
      $("#qr_modal").toggle('hidden')
    }
    $("#modal_fader").toggle('hidden')
  }
}
