import { Controller } from 'stimulus'

export default class extends Controller {
  toggle (event) {
    event.preventDefault()
    fetch('/profile/toggle_contact', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')
      },
      body: JSON.stringify({ contact: event.target.dataset.contact })
    }).then(() => {
      Array.from(event.target.children).forEach(el => {
        el.classList.toggle('hidden')
      })
    })
  }
}
