import React from 'react';

export default props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="none" viewBox="0 0 63 63">
      <rect width="63" height="63" fill="#FE2C55" fillOpacity=".3" rx="15"/>
      <path fill="#FE2C55" d="M46.449 16.03v-1.625c4.492-.252 9.081 3.892 8.935 8.938h-1.625a7.484 7.484 0 0 0-7.31-7.313Z"/>
      <path fill="#FE2C55" d="M51.785 23.359h-1.624a3.82 3.82 0 0 0-3.704-3.697v-1.625c2.404-.18 5.337 2.129 5.328 5.322Zm-8.882 2.222a.795.795 0 0 1 .44.071c.14.066.268.172.375.31.106.137.188.305.24.489.05.184.07.38.058.576v1.841a1.77 1.77 0 0 1-.055.596 1.48 1.48 0 0 1-.243.507c-.109.144-.24.254-.385.322a.814.814 0 0 1-.454.075h-2.522a.837.837 0 0 1-.444-.084 1.082 1.082 0 0 1-.378-.317 1.498 1.498 0 0 1-.245-.493 1.799 1.799 0 0 1-.07-.582v-1.803a1.76 1.76 0 0 1 .053-.617c.053-.198.14-.376.255-.522.114-.146.253-.255.405-.32a.802.802 0 0 1 .472-.049h2.498Z"/>
      <path fill="#FE2C55" d="M48.17 36.668V25.545a3.732 3.732 0 0 0-3.874-3.9H12.779c-.674 0-1.357 0-2.031.073a3.63 3.63 0 0 0-3.135 3.526v19.68a3.738 3.738 0 0 0 3.647 3.68h33.077a3.727 3.727 0 0 0 3.842-3.86l-.008-8.076Zm-1.624 7.947a2.145 2.145 0 0 1-2.356 2.364H11.357a2.111 2.111 0 0 1-2.087-2.072V25.236a2.056 2.056 0 0 1 2.071-1.942h32.768a2.161 2.161 0 0 1 2.388 2.437v2.942a.94.94 0 0 1 0 .114v6.159c.022 3.212.027 6.435.017 9.669h.032Z"/>
      <path fill="#FE2C55" d="M18.23 34.775h2.37v10.368h-1.779l-3.996-5.63v5.63h-2.372V34.775h1.779l3.996 5.623v-5.623Zm10.445 2.275h-3.923v2.007h3.85v2.275h-3.85v3.811h-2.347V34.775H28.7l-.024 2.275Zm6.353 8.304a5.393 5.393 0 0 1-5.394-5.395 5.396 5.396 0 0 1 5.394-5.395 5.287 5.287 0 0 1 2.575.642 4.875 4.875 0 0 1 1.868 1.755l-2.039 1.186c-.23-.4-.571-.725-.983-.934a3.248 3.248 0 0 0-3.623.503 3.112 3.112 0 0 0-.812 2.243 3.081 3.081 0 0 0 .812 2.234 2.948 2.948 0 0 0 2.202.813c.497.007.989-.11 1.43-.341.41-.207.75-.533.974-.935l2.039 1.187a4.818 4.818 0 0 1-1.852 1.755 5.31 5.31 0 0 1-2.591.682Z"/>
    </svg>

  );
};


