// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect () {
    this.sortable = Sortable.create(this.element, {
      sort: true,
      animation: 0,
      ghostClass: "ghost",
      handle: "a>img", 
      // TODO: check on fix and update
      // https://github.com/SortableJS/sortablejs/issues/1941
      onEnd: function () {
        const field_order = []
        document.querySelectorAll('.field_pos').forEach(field => {
          field_order.push(field.dataset.name)
        })
        fetch('/profile/sort', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content')
          },
          body: JSON.stringify({ contacts: field_order })
        })
      }
    })
  }
}
