import React from "react";

export default (props) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16.8078C0.0137493 16.7938 0.0242093 16.7771 0.0306338 16.7587C0.120205 16.4028 0.282582 16.0688 0.507789 15.7771C0.732996 15.4854 1.01628 15.2422 1.34023 15.0625C1.70697 14.8247 2.09092 14.6138 2.489 14.4316C3.44056 14.0188 4.44586 13.7391 5.47579 13.6005L5.7132 13.5627C5.57918 13.4191 5.44899 13.3058 5.34943 13.1849C5.01434 12.7454 4.72564 12.2732 4.48785 11.7758C4.17617 11.1292 4.01648 10.4216 4.02069 9.7056C4.00432 9.07481 4.03247 8.44366 4.10493 7.81673C4.18192 7.37992 4.34954 6.96352 4.5973 6.59363C4.84506 6.22373 5.16761 5.90833 5.54472 5.66718C6.06109 5.29022 6.65979 5.03782 7.29284 4.93023C7.92589 4.82265 8.57568 4.86285 9.19014 5.04763C9.59699 5.16641 9.98402 5.34332 10.3389 5.57274C10.9361 5.94978 11.4086 6.49076 11.6983 7.12917C11.9052 7.55049 12.0101 8.01348 12.0046 8.48161C12.0046 9.17294 12.0046 9.86427 11.9242 10.5518C11.8349 11.1613 11.6171 11.7456 11.2847 12.2669C11.0011 12.7166 10.6944 13.1516 10.3657 13.5702C10.6338 13.6194 10.921 13.6685 11.2081 13.7251C12.0586 13.8806 12.8876 14.1342 13.678 14.4807C14.3418 14.7683 14.9436 15.1786 15.4509 15.6896C15.4811 15.7231 15.5092 15.7585 15.5352 15.7953C15.8744 16.2133 16.051 16.7372 16.033 17.2724C16.0119 17.539 16.0119 17.8068 16.033 18.0733C16.0627 18.3907 15.9735 18.7077 15.7823 18.9646C15.591 19.2215 15.3111 19.4003 14.9952 19.4673C14.9952 19.4673 14.9646 19.4673 14.9493 19.4975H1.07984C0.903466 19.4343 0.731976 19.3586 0.566725 19.2709C0.288374 19.0964 0.0862235 18.8256 0 18.5115L0 16.8078Z"
        fill="#333A49"
      />
      <path
        d="M15.6998 8.68954L16.3048 8.79531C17.2879 8.95638 18.244 9.25002 19.1461 9.66797C19.6784 9.90743 20.1663 10.233 20.5897 10.6313C20.9967 10.9661 21.2685 11.434 21.3555 11.9497C21.3996 12.4941 21.3996 13.0411 21.3555 13.5855C21.311 13.8736 21.1641 14.1367 20.941 14.3277C20.7179 14.5187 20.4332 14.6252 20.1378 14.6282C18.8551 14.6282 17.5761 14.6282 16.3086 14.6282C16.2532 14.6306 16.1988 14.6131 16.1554 14.5791C15.703 14.161 15.186 13.8166 14.6238 13.5591C14.0149 13.2946 13.3831 13.0755 12.7628 12.8337C12.6938 12.8073 12.6211 12.7846 12.5215 12.7544C12.6458 12.5423 12.7509 12.3198 12.8355 12.0895C13.004 11.5342 13.1495 10.9562 13.2835 10.4009C13.4073 9.71124 13.4292 9.00758 13.3486 8.31176C13.3199 7.65265 13.1447 7.00783 12.8355 6.42288C12.5788 5.94268 12.2438 5.50745 11.8437 5.13467C11.2161 4.5398 10.4472 4.10985 9.60747 3.88423C9.40069 3.83134 9.30496 3.75201 9.35091 3.54423C9.39341 3.1416 9.47679 2.7442 9.59981 2.35802C9.80047 1.88284 10.0992 1.45406 10.4769 1.09895C10.8547 0.74384 11.3032 0.470177 11.794 0.295364C12.6982 -0.0613201 13.7002 -0.0960511 14.6276 0.197143C15.2997 0.397916 15.9051 0.772235 16.3814 1.28136C16.9411 1.83135 17.2752 2.56589 17.3195 3.34401C17.3435 4.11897 17.323 4.89465 17.2583 5.66733C17.1531 6.36054 16.8914 7.02157 16.4924 7.60154C16.2476 7.97761 15.9831 8.34077 15.6998 8.68954Z"
        fill="#333A49"
      />
    </svg>
  );
};
