import React from 'react';

export default props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="none" viewBox="0 0 63 63">
      <rect width="63" height="63" fill="#FFC01D" fillOpacity=".3" rx="15"/>
      <path fill="#FFC01D" d="M34.806 40.084c-.171.164-.354.315-.514.491-2.258 2.401-4.504 4.814-6.768 7.215-1.473 1.575-3.465 2.473-5.55 2.503-2.087.03-4.1-.812-5.612-2.345-1.511-1.533-2.4-3.635-2.476-5.857-.076-2.222.667-4.388 2.07-6.034a311.248 311.248 0 0 1 8.458-9.023c1.535-1.518 3.569-2.333 5.662-2.268 2.093.064 4.079 1.004 5.528 2.615.245.213.445.48.586.782.14.302.219.633.23.97.004.399-.107.79-.318 1.119-.212.329-.514.58-.864.72-.33.172-.702.23-1.064.167a1.737 1.737 0 0 1-.958-.52c-.254-.239-.479-.503-.739-.736a3.98 3.98 0 0 0-2.855-1.044 4.024 4.024 0 0 0-2.76 1.302 1451.647 1451.647 0 0 0-8.022 8.544 4.59 4.59 0 0 0-1.23 3.135 4.594 4.594 0 0 0 1.21 3.144 4.037 4.037 0 0 0 2.94 1.311 4.037 4.037 0 0 0 2.95-1.289c1.549-1.62 3.074-3.264 4.605-4.908a.435.435 0 0 1 .273-.186.416.416 0 0 1 .318.066 5.823 5.823 0 0 0 4.528.151l.32-.12.052.095Z"/>
      <path fill="#FFC01D" d="m28.322 22.857.195-.227 7.058-7.523c1.477-1.527 3.45-2.386 5.507-2.4 2.057-.014 4.04.818 5.536 2.325 1.495 1.506 2.388 3.57 2.49 5.76.104 2.19-.59 4.338-1.936 5.997a291.43 291.43 0 0 1-8.464 9.029c-1.535 1.538-3.58 2.364-5.684 2.298-2.106-.066-4.1-1.02-5.548-2.65a2.669 2.669 0 0 1-.768-1.261 2.173 2.173 0 0 1 .118-1.25c.16-.394.434-.724.78-.943a1.78 1.78 0 0 1 1.168-.271c.407.047.788.236 1.085.536a5.515 5.515 0 0 0 1.956 1.493c.72.312 1.51.39 2.272.225a3.871 3.871 0 0 0 2.008-1.157 553.982 553.982 0 0 0 8.199-8.727 4.584 4.584 0 0 0 1.199-3.14 4.578 4.578 0 0 0-1.235-3.123 4.026 4.026 0 0 0-2.945-1.279 4.028 4.028 0 0 0-2.93 1.316 494.014 494.014 0 0 0-4.582 4.877.484.484 0 0 1-.295.207.46.46 0 0 1-.349-.062 5.796 5.796 0 0 0-4.528-.12l-.236.089s-.03-.007-.071-.02Z"/>
    </svg>

  );
};


