import React from "react";
import Link from "@components/menu/Link";
import person from "@images/svgs/sub_menu/person";
import helper from "@images/svgs/sub_menu/helper";
import app from "@images/svgs/sub_menu/app";
import exit from "@images/svgs/sub_menu/exit";
import SettingsIcon from "@components/icons/sub_menu/Settings";
import Users from "@components/icons/sub_menu/Users";

const Settings = ({ changeStep, changeSecondStep }) => {
  const menu_items = [
    // {
    //   href: "/account",
    //   text: I18n.t("sub_menu.account"),
    //   className: "sub_nav-container-body-links-link",
    //   iconClassName: "icon profile",
    //   icon: person,
    //   onClick: () =>
    //     changeSecondStep(false, "account", I18n.t("sub_menu.account")),
    // },
    {
      href: "/card_settings",
      text: I18n.t("sub_menu.card_settings"),
      className: "sub_nav-container-body-links-link",
      iconClassName: "icon profile",
      icon: <SettingsIcon />,
      react_icon: true,
      onClick: () =>
        changeStep(
          false,
          "card_settings",
          I18n.t("sub_menu.card_settings")
        ),
    },
    {
      href: "/main_menu",
      text: I18n.t("sub_menu.main_menu"),
      className: "sub_nav-container-body-links-link",
      iconClassName: "icon profile",
      icon: person,
      onClick: () =>
        changeStep(
          false,
          "main_menu",
          I18n.t("sub_menu.main_menu")
        ),
    },
    {
      href: "/help",
      text: I18n.t("sub_menu.support"),
      className: "sub_nav-container-body-links-link",
      iconClassName: "icon",
      icon: helper,
    },
    {
      href: "/profile",
      text: I18n.t("sub_menu.app"),
      className: "sub_nav-container-body-links-link",
      iconClassName: "icon",
      icon: app,
      onClick: () => changeStep(false, "app", I18n.t("sub_menu.app")),
    },
    {
      href: "/profiles",
      text: I18n.t("sub_menu.cards"),
      className: "sub_nav-container-body-links-link",
      iconClassName: "icon",
      icon: <Users />,
      react_icon: true
    },
    // {
    //   href: "/logout",
    //   text: I18n.t("sub_menu.exit"),
    //   className: "sub_nav-container-body-links-link",
    //   iconClassName: "icon",
    //   icon: exit,
    //   method: "delete",
    // },
  ].map((opt) => <Link key={opt.href} {...opt} />);

  return (
    <nav className="container px-4 sub_nav-container-body-links ">
      {menu_items}
    </nav>
  );
};

export default Settings;
