import React from "react";

export default (props) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_329_5726)">
        <path
          d="M10.3946 20H2.63578C2.6025 19.9856 2.56828 19.9737 2.53338 19.9645C1.27386 19.7181 0.461483 18.9491 0.096256 17.6576C0.05632 17.5165 0.031744 17.3699 0 17.2266V5.93796C0.0129717 5.90953 0.0235923 5.87997 0.031744 5.84961C0.131347 5.10088 0.486257 4.41579 1.03034 3.92199C1.57442 3.4282 2.27057 3.15938 2.98906 3.16563C5.34562 3.16563 7.70082 3.16563 10.0547 3.16563C10.4437 3.16436 10.8291 3.24377 11.189 3.39934C11.5488 3.55491 11.876 3.78357 12.1518 4.07227C12.4275 4.36096 12.6466 4.70402 12.7963 5.08183C12.946 5.45964 13.0235 5.8648 13.0243 6.27414C13.0304 9.81398 13.0304 13.3538 13.0243 16.8937C13.022 17.5794 12.8045 18.2451 12.4056 18.7873C12.0067 19.3296 11.4488 19.718 10.8186 19.8923C10.6813 19.9332 10.537 19.9645 10.3946 20ZM1.48582 11.5624V16.7762C1.48582 17.7772 2.11456 18.4366 3.0679 18.4377H9.95226C10.9189 18.4377 11.5446 17.7815 11.5446 16.7665C11.5446 13.3107 11.5446 9.85457 11.5446 6.39804C11.5446 5.38307 10.9199 4.72689 9.95328 4.72689H3.08634C2.10637 4.72689 1.4848 5.37876 1.4848 6.40666L1.48582 11.5624Z"
          fill="#4479FA"
        />
        <path
          d="M16 8.94307C16 10.8954 16 12.8478 16 14.8002C16.0032 14.9043 15.9949 15.0084 15.9754 15.1105C15.9353 15.293 15.8335 15.4539 15.689 15.5633C15.5445 15.6727 15.3672 15.7232 15.19 15.7053C15.01 15.6868 14.8425 15.6001 14.7187 15.4614C14.595 15.3226 14.5234 15.1412 14.5173 14.951C14.5173 14.8864 14.5173 14.8217 14.5173 14.756V3.26695C14.5173 2.20996 13.9029 1.56347 12.9014 1.56347C10.2226 1.56347 7.54416 1.56347 4.86606 1.56347C4.52404 1.56347 4.2619 1.43202 4.11649 1.09908C4.06689 0.984715 4.04437 0.859401 4.05087 0.733873C4.05736 0.608345 4.09267 0.486342 4.15376 0.378311C4.21486 0.270279 4.29993 0.179437 4.40169 0.113563C4.50345 0.0476886 4.61887 0.00874446 4.73806 6.48716e-05C4.78107 6.48716e-05 4.8251 6.48716e-05 4.86811 6.48716e-05C7.57761 6.48716e-05 10.2871 6.48716e-05 12.9966 6.48716e-05C13.6897 -0.0102149 14.3642 0.236272 14.902 0.6964C15.4398 1.15653 15.8067 1.80099 15.9386 2.51704C15.98 2.74715 15.9996 2.98104 15.9969 3.21524C16.0021 5.12344 16 7.03056 16 8.94307Z"
          fill="#4479FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_329_5726">
          <rect width="16" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
