import React from "react";

const ProBar = ({ profile, profiles, max_length_profiles }) => {
  return (
    <nav className="container px-4 sub_nav-container-body-links">
      <div className="pro_bar">
        <div className="pro_bar__title">
          {I18n.t("promotion.title")}
        </div>
        <button className="pro_bar__button">
          {I18n.t("promotion.button")}
        </button>
      </div>
    </nav>
  );
};

export default ProBar;
