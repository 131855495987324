import React from "react";

const Error = () => {
  return (
    <div className="something_wrong">
      {I18n.t('something_is_wrong')}
    </div>
  );
};

export default Error;
