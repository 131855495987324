import React from "react";
import {
  Settings,
  Error,
  App,
  Account,
  Services,
  Promotion,
  CardSettings,
  Cards,
  MainMenu,
  GoPro,
  LogoUpload,
  PersonalLink,
  MainBtn
} from "./sub_menu";
import InlineSvg from "@components/InlineSvg";
import icon from "@images/icons.svg";

const SubMenu = ({
  main_profile,
  profiles,
  max_length_profiles,
  user_paid,
  user_paid_until,
  user_tariff,
  collapsed,
  className,
  setCollaps,
  step,
  changeStep,
  changeSecondStep,
  changeThirdStep,
  title,
  action,
  activeSetMenuLink,
}) => {
  const currentComponent = {
    settings: (
      <Settings changeStep={changeStep} changeSecondStep={changeSecondStep} />
    ),
    app: <App />,
    account: <Account profile={main_profile} tariff={user_tariff} user_paid={user_paid} user_paid_until={user_paid_until} />,
    services: <Services changeSecondStep={changeSecondStep} />,
    error: <Error />,
    promotion: (
      <Promotion profile={main_profile} changeThirdStep={changeThirdStep} />
    ),
    go_pro: (
      <GoPro />
    ),
    logo_upload: (
      <LogoUpload profile={main_profile} changeThirdStep={changeThirdStep} user_paid={user_paid} />
    ),
    personal_link: (
      <PersonalLink profile={main_profile} changeThirdStep={changeThirdStep} user_paid={user_paid} />
    ),
    main_btn: (
      <MainBtn profile={main_profile} changeThirdStep={changeThirdStep} user_paid={user_paid} />
    ),
    card_settings: (
      <CardSettings
        profile={main_profile}
        profiles={profiles}
        max_length_profiles={max_length_profiles}
      />
    ),
    main_menu: (
      <MainMenu
        changeStep={changeStep}
        profile={main_profile}
        profiles={profiles}
        max_length_profiles={max_length_profiles}
      />
    ),
    cards: (
      <Cards
        profile={main_profile}
        profiles={profiles}
        max_length_profiles={max_length_profiles}
      />
    ),
  };

  return (
    <div className={`sub_nav ${className} ${collapsed && "collapsed"}`}>
      <div className="sub_nav-container">
        <div className="sub_nav-container-header grid grid-cols-3 gap-8">
          <div className="">
            {action && (
              <div className="sub_nav-container-header_action"> Action</div>
            )}
          </div>
          <div className="flex justify-center">
            <div className="sub_nav-container-header_title">
              {title || "Title"}{" "}
            </div>
          </div>
          <div className="">
            <div
              className="sub_nav-container-header_close"
              onClick={() => {
                setCollaps(true);
                activeSetMenuLink();
              }}
            >
              <InlineSvg src={icon} src_name={"close"} />
            </div>
          </div>
        </div>
        <div className="sub_nav-container-body">{currentComponent[step]}</div>
      </div>
    </div>
  );
};

export default SubMenu;
