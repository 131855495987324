import React from "react";

const Burger = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="2.4" rx="1.2" fill="#343A49"/>
      <rect y="6" width="18" height="2.4" rx="1.2" fill="#343A49"/>
      <rect y="12" width="18" height="2.4" rx="1.2" fill="#343A49"/>
    </svg>
  )
}

export default Burger;
