import React from "react";
import Link from "@components/menu/Link";
import person from "@images/svgs/sub_menu/person";
import notifications from "@images/svgs/sub_menu/notifications";
import referral_program from "@images/svgs/sub_menu/referral_program";
import plan from "@images/svgs/sub_menu/plan";
import helper from "@images/svgs/sub_menu/helper";
import app from "@images/svgs/sub_menu/app";
import exit from "@images/svgs/sub_menu/exit";

const MainMenu = ({changeStep, changeSecondStep}) => {
  const menu_items = [
    {
      href: "/account",
      text: I18n.t("sub_menu.account"),
      className: "sub_nav-container-body-links-link",
      iconClassName: "icon profile",
      icon: person,
      onClick: () =>
        changeStep(false, "account", I18n.t("sub_menu.account")),
    },
    // {
    //   href: "/notifications",
    //   text: I18n.t("sub_menu.notifications"),
    //   className: "sub_nav-container-body-links-link",
    //   iconClassName: "icon notifications",
    //   icon: notifications,
    // //   onClick: () =>
    // //     changeSecondStep(false, "notifications", I18n.t("sub_menu.notifications")),
    // },
    // {
    //   href: "/referral_program",
    //   text: I18n.t("sub_menu.referral_program"),
    //   className: "sub_nav-container-body-links-link",
    //   iconClassName: "icon referral_program",
    //   icon: referral_program,
    // //   onClick: () =>
    // //     changeSecondStep(false, "referral_program", I18n.t("sub_menu.referral_program")),
    // },
    {
      href: "/plan",
      text: I18n.t("sub_menu.plan"),
      className: "sub_nav-container-body-links-link",
      iconClassName: "icon plan",
      icon: plan,
      //   onClick: () =>
      //     changeSecondStep(false, "referral_program", I18n.t("sub_menu.rate_plans")),
    },
    {
      href: "/logout",
      text: I18n.t("sub_menu.exit"),
      className: "sub_nav-container-body-links-link",
      iconClassName: "icon",
      icon: exit,
      method: "delete",
    },
  ].map((opt) => <Link key={opt.href} {...opt} />);

  return (
    <nav className="container px-4 sub_nav-container-body-links ">
      {menu_items}
    </nav>
  );
};

export default MainMenu;
