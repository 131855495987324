import React, { useState } from "react";
import Link from "./Link";
import SubMenu from "./SubMenu";
import icon from "@images/icons.svg";
import Services from "@components/icons/menu/Services";

const Menu = ({ main_profile, profiles, max_length_profiles, user_paid, user_paid_until, user_tariff }) => {
  // console.log(main_profile);
  // console.log(profiles);

  const [subMenuCollapsed, setSubMenuCollaps] = useState(true);
  const [subMenu, setSubMenu] = useState("error");
  const [subMenuTitle, setSubMenuTitle] = useState("default");

  const [subSecondMenuCollapsed, setSecondSubMenuCollaps] = useState(true);
  const [subSecondMenu, setSecondSubMenu] = useState("error");
  const [subSecondMenuTitle, setSecondSubMenuTitle] = useState("default");

  const [subThirddMenuCollapsed, setThirdSubMenuCollaps] = useState(true);
  const [subThirdMenu, setThirdSubMenu] = useState("error");
  const [subThirdMenuTitle, setThirdSubMenuTitle] = useState("default");

  const pathname = window.location.pathname;
  const [activeMenuLink, activeSetMenuLink] = useState(pathname);

  const openSubMenu = (collaps, stepSubMenu, subTitle) => {
    setSubMenuCollaps(collaps);
    setSubMenu(stepSubMenu);
    setSubMenuTitle(subTitle);
  };

  const closeMenus = () => {
    setSubMenuCollaps(true);
    setSecondSubMenuCollaps(true);
    setThirdSubMenuCollaps(true);

    activeSetMenuLink(pathname);
  };

  const openSecondSubMenu = (collaps, stepSubMenu, subSecondMenuTitle) => {
    setSecondSubMenuCollaps(collaps);
    setSecondSubMenu(stepSubMenu);
    setSecondSubMenuTitle(subSecondMenuTitle);
  };

  const openThirdSubMenu = (collaps, stepSubMenu, subSecondMenuTitle) => {
    setThirdSubMenuCollaps(collaps);
    setThirdSubMenu(stepSubMenu);
    setThirdSubMenuTitle(subSecondMenuTitle);
  };

  const menu_items = [
    {
      href: "/services",
      text: I18n.t("menu.services"),
      className: "main_nav_link services",
      icon: <Services />,
      react_icon: true,
      onClick: () =>
        openSubMenu(!subMenuCollapsed, "services", I18n.t("menu.services")),
    },
    {
      href: "https://yourcf.ru/meet?utm_source=yourcf&utm_medium=online&utm_campaign=menu",
      text: I18n.t("menu.calendar"),
      className: "main_nav_link",
      icon: icon,
      icon_name: "calendar",
    },
    {
      href: "/profile",
      text: I18n.t("menu.profile"),
      className: "main_nav_link-main",
      icon: icon,
      icon_name: "person",
    },
    {
      href: "/profile/edit",
      text: I18n.t("menu.profile_edit"),
      className: "main_nav_link",
      icon: icon,
      icon_name: "pen",
    },
    {
      href: "/settings",
      text: I18n.t("menu.settings"),
      className: "main_nav_link",
      icon: icon,
      icon_name: "settings",
      onClick: () =>
        openSubMenu(!subMenuCollapsed, "settings", I18n.t("menu.settings")),
    },
  ].map((opt) => (
    <Link
      key={opt.href}
      active={activeMenuLink === opt.href}
      changeActive={activeSetMenuLink}
      {...opt}
    />
  ));

  return (
    <>
      {!subMenuCollapsed && (
        <div
          className={`sub_nav-fade ${subMenuCollapsed && "collapsed"}`}
          onClick={closeMenus}
        ></div>
      )}
      <footer className="nav_container">
        <SubMenu
          main_profile={main_profile}
          profiles={profiles}
          max_length_profiles={max_length_profiles}
          user_paid={user_paid}
          user_paid_until={user_paid_until}
          user_tariff={user_tariff}
          collapsed={subMenuCollapsed}
          changeStep={openSubMenu}
          changeSecondStep={openSecondSubMenu}
          changeThirdStep={openThirdSubMenu}
          title={subMenuTitle}
          setCollaps={setSubMenuCollaps}
          activeSetMenuLink={() => activeSetMenuLink(pathname)}
          step={subMenu}
        />
        <SubMenu
          main_profile={main_profile}
          profiles={profiles}
          max_length_profiles={max_length_profiles}
          user_paid={user_paid}
          user_paid_until={user_paid_until}
          user_tariff={user_tariff}
          className={"second"}
          collapsed={subSecondMenuCollapsed}
          changeStep={openSubMenu}
          changeSecondStep={openSecondSubMenu}
          changeThirdStep={openThirdSubMenu}
          title={subSecondMenuTitle}
          setCollaps={setSecondSubMenuCollaps}
          activeSetMenuLink={() => activeSetMenuLink(pathname)}
          step={subSecondMenu}
        />
        <SubMenu
          main_profile={main_profile}
          profiles={profiles}
          max_length_profiles={max_length_profiles}
          user_paid={user_paid}
          user_paid_until={user_paid_until}
          user_tariff={user_tariff}
          className={"third"}
          collapsed={subThirddMenuCollapsed}
          changeStep={openSubMenu}
          changeSecondStep={openSecondSubMenu}
          changeThirdStep={openThirdSubMenu}
          title={subThirdMenuTitle}
          setCollaps={setThirdSubMenuCollaps}
          activeSetMenuLink={() => activeSetMenuLink(pathname)}
          step={subThirdMenu}
        />

        <nav className="main_nav">
          <div className="container ml-auto mr-auto flex justify-between items-center px-1 ">
            {menu_items}
          </div>
        </nav>
      </footer>
    </>
  );
};

export default Menu;
