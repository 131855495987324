import React, {useState, useEffect} from "react";
import Info from "../../icons/sub_menu/Info";
import Router from "@components/router.js";
import {toast} from "react-toastify";

const hints = {
  ok: I18n.t("sub_menu.your_links.ok"),
  busy: I18n.t("sub_menu.your_links.busy"),
  littleSymbols: I18n.t("sub_menu.your_links.littleSymbols"),
  manySymbols: I18n.t("sub_menu.your_links.manySymbols"),
  process: I18n.t("sub_menu.your_links.process"),
  invalidSymbols: I18n.t("activerecord.errors.models.profile.attributes.slug.invalid")
}

const stopWords = [
  'slugs',
  'slug',
  'profile',
  'admin',
  'login',
  'logout',
  'password',
  'cancel',
  'sign_up',
  'edit',
  'payments',
  'tariffs',
  'payment',
  'profiles',
  'plan',
  'views',
  'saved',
  'accounts',
  'oferta',
  'help'
]


const PersonalLink = ({profile, changeThirdStep, user_paid}) => {
  const [inputValue, setInputValue] = useState('')
  const [className, setClassName] = useState('process')
  const [status, setStatus] = useState(hints.process)
  const validSymbolsRegexp = /^[A-Za-z0-9]*$/

  useEffect(() => {
    if (stopWords.includes(inputValue)) {
      setStatus(hints.busy)
      setClassName('err')
    } else if (inputValue.length < 5) {
      setStatus(hints.littleSymbols)
      setClassName('err')
    } else if (inputValue.length > 12) {
      setStatus(hints.manySymbols)
      setClassName('err')
    } else if (!validSymbolsRegexp.test(inputValue)) {
      setStatus(hints.invalidSymbols)
      setClassName('err')
    } else {
      const onResponse = (res_data) => {
        if (res_data.status) {
          setStatus(hints.ok)
          setClassName('ok')
          toast.success(res_data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            zIndex: 9999,
          });
        } else {
          setStatus(hints.busy)
          setClassName('err')
          toast.error(res_data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            zIndex: 9999,
          });
        }
      };

      const res_url = '/slugs?slug=' + inputValue
      Router.fetchGetRequest(res_url, onResponse);
    }
  }, [inputValue]);

  const onSubmit = () => {
    const path = "/profile.json";
    const data = { "profile[slug]": inputValue };
    const method = "PATCH";
    const onResponse = (res_data) => {
      if (res_data.success) {
        toast.success(I18n.t("notify.success"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
        setTimeout(function() {
          window.location.reload()
        }, 2000);
      } else {
        toast.error(res_data.errors || I18n.t("notify.error"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    };

    Router.fetchWithData(path, data, method, onResponse);
  };

  const save = () => {
    if (user_paid) {
      onSubmit()
    } else {
      changeThirdStep(false, "go_pro", I18n.t("sub_menu.go_pro_txt.header"))
    }
  }

  const handleInput = (e) => {
    let slug = e.target.value
    setInputValue(slug)
  };

  return (
    <>
      <button className='save_btn' onClick={save}>
        {I18n.t("sub_menu.your_links.save")}
      </button>

      <div className='link'>

        <section className='row row-1'>
          <div className='row__header'>
            {I18n.t("sub_menu.your_links.current_link")}
          </div>
          <div className='row__txt'>
            {I18n.t("sub_menu.your_links.hash")}{profile.slug}
          </div>
        </section>

        <section className='row row-2'>
          <div className='row__header'>
            {I18n.t("sub_menu.your_links.create_personal_link")}
          </div>
          <div className='row__input'>
            <div className='row__txt'>
              {I18n.t("sub_menu.your_links.hash")}
            </div>
            <input type='text'
                   className='link__input'
                   value={inputValue}
                   onChange={handleInput}/>
          </div>
          <div className='link__hint'>
            {inputValue && <span className={className}>{status}</span>}
          </div>
        </section>

        <div className='link_info'>
          <div>
            <Info/>
          </div>
          <div className='main-btn_info__txt'>
            {I18n.t("sub_menu.your_links.info1")}
          </div>
          <div className='main-btn_info__txt'>
            {I18n.t("sub_menu.your_links.info2")}
          </div>
        </div>

      </div>
    </>
  )
}

export default PersonalLink;
