import React from "react";

const Period = ({activeTab, setActiveTab, rateData}) => {

  return (
    <div className="period">
      <ul className="period-list">
        <li className={activeTab === 'one_month' ? 'period-list__item period-list__item_active' : 'period-list__item'}
            onClick={() => setActiveTab('one_month')}>
          {I18n.t("plans.one_month")}
        </li>
        <li className={activeTab === 'one_quarter' ? 'period-list__item period-list__item_active' : 'period-list__item'}
            onClick={() => setActiveTab('one_quarter')}>
          <span className='period-list__right-line'
                style={{display: activeTab === 'one_month' ? 'block' : 'none'}}></span>
          <span className='period-list__left-line'
                style={{display: activeTab === 'one_year' ? 'block' : 'none'}}></span>
          {I18n.t("plans.one_quarter")}
          <span className="period-list__discount">
            {rateData.find(i => i.key === "one_quarter")['discount']}
          </span>
        </li>
        <li className={activeTab === 'one_year' ? 'period-list__item period-list__item_active' : 'period-list__item'}
            onClick={() => setActiveTab('one_year')}>
          {I18n.t("plans.one_year")}
          <span className="period-list__discount">
            {rateData.find(i => i.key === "one_year")['discount']}
          </span>
        </li>
      </ul>
    </div>
  )
}

export default Period;
