import React from 'react';

export default props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="none" viewBox="0 0 63 63">
      <rect width="63" height="63" fill="#2C4EFE" fillOpacity=".3" rx="15"/>
      <path fill="#2C4EFE" d="M17.767 25.47h6.121v3.704H13.85V12.337h3.917V25.47Zm21.361 1.515c-1.73 1.684-3.836 2.526-6.317 2.526-2.481 0-4.587-.842-6.317-2.526-1.73-1.684-2.595-3.76-2.595-6.23 0-2.47.865-4.546 2.595-6.23C28.224 12.843 30.33 12 32.811 12c2.481 0 4.587.842 6.317 2.526 1.73 1.683 2.595 3.76 2.595 6.23 0 2.469-.865 4.545-2.595 6.229Zm-9.892-2.622c.963.93 2.155 1.395 3.575 1.395 1.42 0 2.603-.465 3.55-1.395.963-.93 1.445-2.133 1.445-3.608s-.482-2.678-1.445-3.608c-.947-.93-2.13-1.395-3.55-1.395s-2.611.465-3.575 1.395c-.946.93-1.42 2.133-1.42 3.608 0 1.476.474 2.678 1.42 3.608Zm1.466 15.92v1.443c0 2.517-.8 4.53-2.4 6.037C26.72 49.254 24.703 50 22.255 50c-2.66 0-4.872-.842-6.635-2.526C13.873 45.791 13 43.722 13 41.27c0-2.454.865-4.53 2.595-6.23 1.747-1.7 3.877-2.55 6.39-2.55 1.6 0 3.061.353 4.383 1.059 1.339.705 2.375 1.635 3.11 2.79l-3.33 1.876c-.376-.577-.939-1.05-1.69-1.419-.734-.369-1.566-.553-2.497-.553-1.453 0-2.66.473-3.623 1.419-.947.946-1.42 2.157-1.42 3.632 0 1.46.49 2.654 1.468 3.584.98.914 2.286 1.37 3.918 1.37 2.269 0 3.73-.865 4.382-2.597h-4.578v-3.367h8.594Zm16.703 7.191C45.675 49.158 43.569 50 41.088 50c-2.481 0-4.587-.842-6.317-2.526-1.73-1.683-2.595-3.76-2.595-6.23 0-2.469.865-4.545 2.595-6.229 1.73-1.684 3.836-2.526 6.317-2.526 2.48 0 4.587.842 6.317 2.526C49.135 36.7 50 38.775 50 41.245c0 2.47-.865 4.546-2.595 6.23Zm-9.892-2.621c.963.93 2.155 1.395 3.575 1.395 1.42 0 2.603-.465 3.55-1.395.963-.93 1.445-2.133 1.445-3.608s-.482-2.678-1.445-3.608c-.947-.93-2.13-1.395-3.55-1.395s-2.612.465-3.575 1.395c-.946.93-1.42 2.133-1.42 3.608s.474 2.678 1.42 3.608Z"/>
    </svg>
  );
};


