import React, {useState} from "react";
import Burger from "../../icons/sub_menu/Burger";
import Info from "../../icons/sub_menu/Info";
import Router from "@components/router.js";
import {toast} from "react-toastify";

const NullifyInputs = ({setTextFromSelectList, setBtnInput, setLinkInput}) => {
  setTextFromSelectList(I18n.t("sub_menu.main_btns.add_to_contacts"))
  setBtnInput('')
  setLinkInput('')
}

const SelectList = ({setTextFromSelectList, setBtnInput, setLinkInput}) => {
  return (
    <div className='select'>
      <ul className='select-list'>
        <li className='select-list__item'
            onClick={() => NullifyInputs({setTextFromSelectList, setBtnInput, setLinkInput})}>
          {I18n.t("sub_menu.main_btns.add_to_contacts")}
        </li>
        <li className='select-list__item' onClick={() => setTextFromSelectList(I18n.t("sub_menu.main_btns.open_link"))}>
          {I18n.t("sub_menu.main_btns.open_link")}
        </li>
      </ul>
    </div>
  )
}

const Link = ({btnInput, setBtnInput, linkInput, setLinkInput}) => {
  return (
    <>
      <div className='main_link'>
        <div className='main_btn_action__txt'>{I18n.t("sub_menu.main_btns.btn_name")}</div>
        <input type='text'
               className='main_btn_action__select main_link__input'
               placeholder={I18n.t("sub_menu.main_btns.enter_name")}
               value={btnInput}
               onChange={(e) => setBtnInput(e.target.value)}/>
      </div>
      <div className='main_link'>
        <div className='main_btn_action__txt'>{I18n.t("sub_menu.main_btns.link")}</div>
        <input type='text'
               className='main_btn_action__select main_link__input'
               placeholder='https://yourcf.online'
               value={linkInput}
               onChange={(e) => setLinkInput(e.target.value)}/>
      </div>
    </>
  )
}

const MainBtn = ({profile, user_paid, changeThirdStep}) => {
  const defaultMainButtonName = I18n.t('sub_menu.main_btns.add_to_contacts')
  console.log(defaultMainButtonName, profile.main_button_name)
  console.log(defaultMainButtonName === profile.main_button_name)
  const currentButton = (profile.main_button_name === defaultMainButtonName ? I18n.t("sub_menu.main_btns.add_to_contacts") : I18n.t("sub_menu.main_btns.open_link"))
  const [showSelectList, setShowSelectList] = useState(false);
  const [textFromSelectList, setTextFromSelectList] = useState(currentButton);
  const [btnInput, setBtnInput] = useState(profile.main_button_name)
  const [linkInput, setLinkInput] = useState(profile.main_button_link)

  const save = () => {
    const onResponse = (res_data) => {
      if (res_data.success) {
        toast.success(res_data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      } else {
        toast.error(res_data.errors, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    };

    if (user_paid) {
      Router.fetchWithData('/profile.json', {
        'profile[main_button_name]': btnInput === '' ? defaultMainButtonName : btnInput,
        'profile[main_button_link]': linkInput === '' ? '' : linkInput
      }, 'PATCH', onResponse)
    } else {
      changeThirdStep(false, "go_pro", I18n.t("sub_menu.go_pro"))
    }
  }

  return (
    <>
      <button className='save_btn' onClick={save}>{I18n.t("sub_menu.main_btns.save")}</button>
      <div className='main_btn'>
        <div>
          <button className='main_btn_btn'>
            {btnInput === '' ? I18n.t("sub_menu.main_btns.add_to_contacts") : btnInput}
          </button>
        </div>

        <div className='main_btn_action' onClick={() => setShowSelectList(!showSelectList)}>
          <div className='main_btn_action__txt'>{I18n.t("sub_menu.main_btns.action")}</div>
          <div className='main_btn_action__select'>
            <div className='main_btn_action__select_txt'>{textFromSelectList}</div>
            <Burger/>
          </div>
          {showSelectList && <SelectList setTextFromSelectList={setTextFromSelectList} setBtnInput={setBtnInput} setLinkInput={setLinkInput}/>}
        </div>

        {textFromSelectList === I18n.t("sub_menu.main_btns.open_link") &&
          <Link btnInput={btnInput} setBtnInput={setBtnInput} linkInput={linkInput} setLinkInput={setLinkInput}/>}
      </div>
    </>
  )
}

export default MainBtn;
