import React from "react";
import Router from "@components/router.js";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Account = ({ profile, tariff, user_paid, user_paid_until }) => {
  const { register, setValue, getValues } = useForm();
  const { email, lang, access_by_link, visibility_search_engines } = profile;
  register("email", { value: email });
  register("password", { value: "" });
  register("access_by_link", { value: access_by_link });
  register("visibility_search_engines", { value: visibility_search_engines });
  register("lang", { value: lang || "ru" });

  const onSubmit = () => {
    const path = "/accounts";
    const data = getValues();
    const method = "PATCH";

    const onResponse = (res_data) => {
      if (res_data.success) {
        toast.success(I18n.t("notify.success"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      } else {
        toast.error(I18n.t("notify.error"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    };

    Router.fetchWithData(path, data, method, onResponse);
  };

  const onUnsubscribe = () => {
    const path = "/payments";

    const onResponse = (res_data) => {
      if (res_data.success) {
        toast.success(I18n.t("notify.order_unsubscribe_success"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      } else {
        toast.error(res_data['message'], {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    };

    Router.fetchDeleteRequest(path, onResponse);
  }

  const PaymentBlock = ({tariff, user_paid, user_paid_until}) => {
    if(user_paid === true && tariff !== undefined) {
      const { next_payment, period, price, canceled_at } = tariff;
      return (
        <div className="subscription_block">
          <hr/>
          <div>
            <p className="subscription_header">
              <b>{I18n.t('sub_menu.account_subscription.header')}</b>
            </p>
            <p>{I18n.t('sub_menu.account_subscription.paid_until')}: {user_paid_until}</p>
            <p>{I18n.t('sub_menu.account_subscription.next_payment')}: {next_payment}</p>
            <p>{I18n.t('sub_menu.account_subscription.tariff')}: {I18n.t(`plans.${period}`)} = {price} руб</p>
            <UnsubscribeLink canceled_at={canceled_at} next_payment={next_payment}/>
          </div>
        </div>
      )
    }
  }

  const UnsubscribeLink = ({canceled_at, next_payment}) => {
    if(canceled_at && next_payment) {
      return (
        <p className='unsubscribed'>{I18n.t('sub_menu.account_subscription.canceled_on', { date: canceled_at})}</p>
      )
    } else {
      return (
        <p className='unsubscribe_button'>
          <span onClick={() => onUnsubscribe()}>{I18n.t('sub_menu.account_subscription.cancel_button')}</span>
        </p>
      )
    }
  }

  return (
    <nav className="container px-4 sub_nav-container-body-links">
      <form className="form_v1">
        <div className="form_v1-group">
          <label className="form_v1-group-label">{I18n.t("form.email")}</label>
          <div className="form_v1-group-input_block">
            <input
              name="email"
              className="form_v1-group-input_block-input"
              value={email}
              onChange={(e) => setValue("email", e.target.value)}
            />
            <span
              className="form_v1-group-input_block-action"
              onClick={() => onSubmit()}
            >
              {I18n.t("form.save")}
            </span>
          </div>
        </div>
        <div className="form_v1-group">
          <label className="form_v1-group-label">
            {I18n.t("form.password")}
          </label>
          <div className="form_v1-group-input_block">
            <input
              name="password"
              className="form_v1-group-input_block-input"
              placeholder="•••••••••••••"
              onChange={(e) => setValue("password", e.target.value)}
            />
            <span
              className="form_v1-group-input_block-action"
              onClick={() => onSubmit()}
            >
              {I18n.t("form.save")}
            </span>
          </div>
        </div>
      </form>
      <PaymentBlock tariff={tariff} user_paid={user_paid} user_paid_until={user_paid_until}/>
    </nav>
  );
};

export default Account;
