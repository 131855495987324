import React from "react";

export default (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00195492 11.0895V5.72833C-0.0202226 5.15512 0.146798 4.59051 0.477253 4.12152C0.736638 3.74882 1.08381 3.44568 1.48816 3.23888C1.8925 3.03208 2.34157 2.92794 2.7957 2.93571H8.59183C8.71341 2.93502 8.83445 2.95194 8.95118 2.98592C9.11118 3.03655 9.24928 3.13993 9.34292 3.27914C9.43657 3.41836 9.48022 3.58517 9.46677 3.75238C9.45331 3.9196 9.38354 4.07733 9.26884 4.19979C9.15415 4.32225 9.00128 4.40225 8.83524 4.42667C8.71769 4.44541 8.59879 4.45443 8.47975 4.45369H2.86525C2.6777 4.44756 2.49056 4.47501 2.31269 4.53478C2.09911 4.60964 1.91211 4.74535 1.77478 4.92518C1.63745 5.10501 1.5558 5.32108 1.53988 5.54676C1.53411 5.62519 1.53411 5.70393 1.53988 5.78236C1.53988 9.32298 1.53988 12.8649 1.53988 16.4081C1.52765 16.7083 1.61754 17.0037 1.7949 17.2463C1.90819 17.3879 2.05155 17.5026 2.2146 17.5821C2.37766 17.6616 2.55633 17.7039 2.73773 17.7059H13.5881C13.7934 17.7118 13.9977 17.6736 14.187 17.5939C14.394 17.5054 14.5707 17.3585 14.6956 17.1713C14.8205 16.984 14.8882 16.7645 14.8903 16.5394C14.8941 16.4738 14.8941 16.4081 14.8903 16.3425C14.8903 14.4936 14.8903 12.6435 14.8903 10.7921C14.8877 10.6624 14.8993 10.5329 14.925 10.4058C14.9468 10.2987 14.9924 10.1979 15.0584 10.1108C15.1243 10.0237 15.209 9.95248 15.3062 9.90243C15.4033 9.85239 15.5105 9.8248 15.6197 9.82167C15.729 9.81853 15.8376 9.83998 15.9374 9.88438C16.0753 9.93487 16.1943 10.0263 16.2786 10.1465C16.3629 10.2666 16.4084 10.4097 16.4089 10.5564C16.4089 10.6607 16.4089 10.765 16.4089 10.8693C16.4089 12.695 16.4089 14.5232 16.4089 16.3541C16.4274 16.8503 16.3126 17.3424 16.0766 17.7793C15.8977 18.1186 15.6472 18.415 15.3422 18.6478C15.0373 18.8806 14.6853 19.0442 14.3107 19.1273C14.0725 19.1827 13.8288 19.2112 13.5842 19.2123H2.82273C2.32317 19.2318 1.82797 19.1126 1.3921 18.8678C0.956226 18.623 0.596763 18.2623 0.3536 17.8256C0.108839 17.404 -0.0129803 16.9224 0.00195492 16.4351V11.0779V11.0895Z"
        fill="#4479FA"
      />
      <path
        d="M16.7605 1.50282H12.7767C12.5698 1.51302 12.3656 1.45316 12.1971 1.3329C12.0827 1.23867 11.9979 1.11356 11.9527 0.972484C11.9074 0.831411 11.9038 0.680307 11.942 0.537199C11.9823 0.394162 12.0646 0.266529 12.1782 0.1708C12.2919 0.0750703 12.4317 0.0156541 12.5796 0.000306768C12.6685 0.000306768 12.7612 0.000306768 12.8501 0.000306768H18.5148C18.6017 -0.00169443 18.6886 0.00610204 18.7737 0.0235044C18.9531 0.0544924 19.1149 0.150333 19.2281 0.292776C19.3414 0.435218 19.3984 0.61432 19.3881 0.796003C19.3881 1.723 19.3881 2.64998 19.3881 3.57698V6.60903C19.3711 6.78899 19.2914 6.9573 19.163 7.08462C19.0347 7.21194 18.8657 7.29023 18.6855 7.30585C18.5054 7.32147 18.3254 7.27343 18.177 7.17011C18.0286 7.06678 17.9212 6.91473 17.8734 6.74038C17.8695 6.66705 17.8695 6.59353 17.8734 6.5202C17.8734 5.25072 17.8734 3.97867 17.8734 2.70405V2.60362L17.7227 2.74267C17.5681 2.88944 17.4097 3.03236 17.2552 3.183L8.36776 12.005C8.2378 12.1509 8.0689 12.2567 7.88088 12.3101C7.7302 12.3401 7.574 12.3245 7.43221 12.2653C7.29043 12.2062 7.1695 12.1061 7.08488 11.9779C7.00373 11.852 6.96284 11.7043 6.96767 11.5546C6.9725 11.4049 7.02283 11.2602 7.11194 11.1398C7.16468 11.0709 7.22283 11.0063 7.28582 10.9466L16.1732 2.12081C16.3548 1.94314 16.548 1.71912 16.7605 1.50282Z"
        fill="#4479FA"
      />
    </svg>
  );
};
