import React from "react";

export default (props) => {
  return (
    <svg
      width="29"
      height="31"
      viewBox="0 0 29 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.533 28.8219C23.533 27.8618 22.1512 25.2508 22.1512 25.2508L20.6348 25.7443L20.249 25.834C18.7146 25.52 16.7585 25.3315 14.623 25.3315C12.4875 25.3315 10.5314 25.52 8.99711 25.834L8.61128 25.7443L7.09488 25.2508C7.09488 25.2508 5.71307 27.8708 5.71307 28.8219C5.71307 29.5756 9.70595 30.6972 14.623 30.6972C19.5401 30.6972 23.533 29.5846 23.533 28.8219Z"
        fill="url(#paint0_linear_193_4475)"
      />
      <path
        d="M8.37793 29.9261C9.35596 30.1056 10.5493 30.2581 11.8773 30.3479C12.1914 28.8943 12.7656 26.7318 13.5104 25.359C12.4246 25.3949 11.4107 25.4757 10.4865 25.6013C9.63412 26.678 8.87143 28.7148 8.37793 29.9261Z"
        fill="url(#paint1_linear_193_4475)"
      />
      <path
        d="M14.7218 30.6977C19.594 30.6887 23.5331 29.5761 23.5331 28.8224C23.551 28.0866 22.6179 25.9601 22.1513 25.2512C21.8642 25.0807 22.1154 25.5383 22.1154 25.5383C22.1154 25.5383 21.7744 25.5383 21.2361 25.5473L20.6349 25.7447L20.2491 25.8344C19.8004 25.7447 19.3159 25.664 18.8044 25.5922C15.9332 25.664 12.3351 25.8075 12.9991 26.0857C13.7528 26.4087 14.4168 29.2172 14.65 30.6977C14.668 30.6977 14.6949 30.6977 14.7218 30.6977Z"
        fill="url(#paint2_radial_193_4475)"
      />
      <path
        d="M23.5328 28.8219C23.5328 28.786 23.5328 28.7591 23.5328 28.7322C23.452 27.7452 22.1509 25.2508 22.1509 25.2508L22.0702 25.2777C22.124 25.3674 22.1509 25.4661 22.1509 25.5558C22.1509 26.5967 18.7772 27.4491 14.6228 27.4491C10.4594 27.4491 7.09464 26.6057 7.09464 25.5558C7.09464 25.4571 7.12156 25.3674 7.1754 25.2777L7.09464 25.2508C7.09464 25.2508 6.88827 25.6545 6.49347 26.4711C7.94706 27.7721 11.5451 29.136 14.6228 29.136C19.1451 29.136 20.7333 27.808 22.5816 29.4949C23.1559 29.2975 23.4879 29.0732 23.5328 28.8668C23.5238 28.8489 23.5328 28.8399 23.5328 28.8219Z"
        fill="url(#paint3_radial_193_4475)"
      />
      <path
        d="M22.2495 25.5564C22.2495 26.5972 18.8309 27.4496 14.6226 27.4496C10.4144 27.4496 6.99579 25.5564 6.99579 25.5564C6.99579 24.5155 10.4144 23.6631 14.6226 23.6631C18.8309 23.6631 22.2495 24.5155 22.2495 25.5564Z"
        fill="url(#paint4_linear_193_4475)"
      />
      <path
        d="M14.6141 28.1584C22.2357 28.1584 28.4142 21.9798 28.4142 14.3583C28.4142 6.73666 22.2357 0.558136 14.6141 0.558136C6.99249 0.558136 0.813965 6.73666 0.813965 14.3583C0.813965 21.9798 6.99249 28.1584 14.6141 28.1584Z"
        fill="url(#paint5_radial_193_4475)"
      />
      <path
        d="M14.6141 28.1584C22.2357 28.1584 28.4142 21.9798 28.4142 14.3583C28.4142 6.73666 22.2357 0.558136 14.6141 0.558136C6.99249 0.558136 0.813965 6.73666 0.813965 14.3583C0.813965 21.9798 6.99249 28.1584 14.6141 28.1584Z"
        fill="url(#paint6_radial_193_4475)"
      />
      <path
        d="M14.6141 28.1584C22.2357 28.1584 28.4142 21.9798 28.4142 14.3583C28.4142 6.73666 22.2357 0.558136 14.6141 0.558136C6.99249 0.558136 0.813965 6.73666 0.813965 14.3583C0.813965 21.9798 6.99249 28.1584 14.6141 28.1584Z"
        fill="url(#paint7_radial_193_4475)"
      />
      <path
        d="M14.6141 28.1584C22.2357 28.1584 28.4142 21.9798 28.4142 14.3583C28.4142 6.73666 22.2357 0.558136 14.6141 0.558136C6.99249 0.558136 0.813965 6.73666 0.813965 14.3583C0.813965 21.9798 6.99249 28.1584 14.6141 28.1584Z"
        fill="url(#paint8_radial_193_4475)"
      />
      <path
        d="M14.614 28.5982C19.8421 28.5982 24.0802 22.4196 24.0802 14.798C24.0802 7.17645 19.8421 0.997925 14.614 0.997925C9.3859 0.997925 5.14771 7.17645 5.14771 14.798C5.14771 22.4196 9.3859 28.5982 14.614 28.5982Z"
        fill="url(#paint9_linear_193_4475)"
      />
      <path
        d="M16.8484 26.3279C23.122 26.3279 28.2079 20.9689 28.2079 14.3583C28.2079 7.74761 23.122 2.3886 16.8484 2.3886C10.5747 2.3886 5.48883 7.74761 5.48883 14.3583C5.48883 20.9689 10.5747 26.3279 16.8484 26.3279Z"
        fill="url(#paint10_linear_193_4475)"
      />
      <path
        d="M26.7273 7.72747C25.9287 16.2695 18.7415 22.9543 9.9931 22.9543C7.37305 22.9543 4.89657 22.3531 2.6803 21.2853C5.06705 25.3948 9.52652 28.1674 14.623 28.1674C22.2499 28.1674 28.4232 21.9852 28.4232 14.3673C28.4232 11.9536 27.804 9.69251 26.7273 7.72747Z"
        fill="url(#paint11_linear_193_4475)"
      />
      <path
        d="M15.3589 0.576073C19.8273 2.66673 22.9229 7.19798 22.9229 12.456C22.9229 19.697 17.0547 25.5652 9.81369 25.5652C8.37805 25.5652 7.00521 25.3319 5.71313 24.9102C8.11784 26.9381 11.2224 28.1673 14.6141 28.1673C22.241 28.1673 28.4142 21.9851 28.4142 14.3672C28.4232 6.98263 22.6358 0.961902 15.3589 0.576073Z"
        fill="url(#paint12_linear_193_4475)"
      />
      <path
        d="M16.3638 3.07959C17.7905 5.25997 16.0587 8.92086 12.4965 11.2538C8.93434 13.5867 4.88762 13.7213 3.45197 11.5409C2.01633 9.36052 3.75705 5.69964 7.31924 3.36672C10.8814 1.0338 14.9281 0.899204 16.3638 3.07959Z"
        fill="url(#paint13_radial_193_4475)"
      />
      <path
        d="M12.003 25.6371C19.8721 25.6371 26.2607 19.2575 26.2607 11.3794C26.2607 9.08236 25.7134 6.91096 24.7533 4.99078C27.0324 7.44932 28.4231 10.7423 28.4231 14.3673C28.4231 21.9942 22.2409 28.1674 14.623 28.1674C10.352 28.1674 6.52959 26.2293 3.99927 23.1786C6.27835 24.7309 9.03299 25.6371 12.003 25.6371Z"
        fill="url(#paint14_radial_193_4475)"
      />
      <path
        d="M14.8565 18.9164C11.2943 18.9164 6.89762 20.1457 4.51086 23.7617C7.03221 26.4715 10.6213 28.1673 14.6142 28.1673C22.2411 28.1673 28.4143 21.9851 28.4143 14.3672C28.4143 11.7113 27.6606 9.22582 26.3596 7.12619C25.875 14.43 19.3339 18.9164 14.8565 18.9164Z"
        fill="url(#paint15_radial_193_4475)"
      />
      <path
        d="M5.95538 25.1077C6.02715 25.1704 6.09891 25.2243 6.17067 25.2781C6.09891 25.2243 6.02715 25.1615 5.95538 25.1077Z"
        fill="url(#paint16_radial_193_4475)"
      />
      <path
        d="M5.72198 24.919C5.79375 24.9818 5.86551 25.0356 5.93727 25.0984C5.86551 25.0356 5.79375 24.9729 5.72198 24.919Z"
        fill="url(#paint17_radial_193_4475)"
      />
      <path
        d="M9.81359 25.5652C17.0546 25.5652 22.9228 19.697 22.9228 12.456C22.9228 11.0562 22.6985 9.71029 22.2947 8.4541C21.864 9.34241 21.1552 10.2397 20.0695 11.11C14.2551 15.7938 9.54441 10.7601 0.831848 15.1119C1.03822 19.042 2.89558 22.5324 5.71303 24.9102C7.00511 25.3319 8.38692 25.5652 9.81359 25.5652Z"
        fill="url(#paint18_linear_193_4475)"
      />
      <path
        d="M5.72207 24.9103C7.01415 25.332 8.38698 25.5653 9.82262 25.5653C11.0339 25.5653 12.2094 25.4038 13.322 25.0898C7.35511 23.385 2.9764 17.8936 2.9764 11.3794C2.9764 9.08237 3.52374 6.91096 4.48383 4.99078C2.20474 7.44932 0.813965 10.7423 0.813965 14.3584C0.813965 19.4369 3.55066 23.8695 7.63327 26.2652C6.96031 25.8704 6.31427 25.4128 5.72207 24.9103Z"
        fill="url(#paint19_linear_193_4475)"
      />
      <path
        d="M15.3589 0.576073C19.8273 2.66673 22.9229 7.19798 22.9229 12.456C22.9229 19.697 17.0547 25.5652 9.81369 25.5652C8.37805 25.5652 7.00521 25.3319 5.71313 24.9102C8.11784 26.9381 11.2224 28.1673 14.6141 28.1673C22.241 28.1673 28.4142 21.9851 28.4142 14.3672C28.4232 6.98263 22.6358 0.961902 15.3589 0.576073Z"
        fill="url(#paint20_linear_193_4475)"
      />
      <path
        d="M14.6141 28.1584C22.2357 28.1584 28.4142 21.9798 28.4142 14.3583C28.4142 6.73666 22.2357 0.558136 14.6141 0.558136C6.99249 0.558136 0.813965 6.73666 0.813965 14.3583C0.813965 21.9798 6.99249 28.1584 14.6141 28.1584Z"
        fill="url(#paint21_radial_193_4475)"
      />
      <path
        d="M21.6398 7.77233L22.8332 9.31564L21.9898 7.56595C22.2948 7.43136 22.6448 7.3865 22.9947 7.46725C22.6179 7.37752 22.3038 7.16218 22.0885 6.87505L23.0396 6.13928L21.9539 6.6597C21.7834 6.32771 21.7206 5.94188 21.8103 5.55605C21.7296 5.90599 21.5322 6.20209 21.2719 6.41744L20.1055 4.90104L20.9399 6.62381C20.6259 6.76737 20.267 6.82121 19.8991 6.73148C20.2939 6.82121 20.608 7.0545 20.8233 7.35958L19.8991 8.0774L20.9489 7.56595C21.1104 7.88897 21.1643 8.26583 21.0745 8.64268C21.1643 8.29275 21.3706 7.98767 21.6398 7.77233Z"
        fill="white"
      />
      <path
        d="M16.3547 19.2664L16.4624 20.3072L16.5701 19.2664C16.7495 19.2933 16.92 19.374 17.0546 19.5086C16.911 19.3651 16.8303 19.1766 16.8123 18.9792L17.4494 18.9164L16.8034 18.8536C16.8123 18.6562 16.9021 18.4588 17.0546 18.3063C16.92 18.4409 16.7495 18.5216 16.5701 18.5485L16.4624 17.5257L16.3547 18.5485C16.1663 18.5306 15.9958 18.4498 15.8523 18.3063C16.0048 18.4588 16.0855 18.6562 16.0945 18.8536L15.4664 18.9164L16.0855 18.9792C16.0676 19.1677 15.9868 19.3561 15.8433 19.5086C15.9958 19.3651 16.1753 19.2843 16.3547 19.2664Z"
        fill="white"
      />
      <path
        d="M5.08504 11.1818L4.61845 12.8866L5.426 11.3164C5.69519 11.46 5.91053 11.6932 6.04512 11.9893C5.90156 11.6663 5.89259 11.3254 5.98231 11.0113L7.03213 11.2984L6.06307 10.8049C6.20663 10.4999 6.45787 10.2397 6.78986 10.0961C6.49376 10.2307 6.17074 10.2486 5.87464 10.1768L6.33225 8.50791L5.54265 10.0602C5.26449 9.91664 5.0312 9.67437 4.89661 9.3693C5.04017 9.70129 5.04915 10.0602 4.95045 10.3832L3.92755 10.1051L4.86969 10.5896C4.72613 10.8857 4.48386 11.128 4.16084 11.2715C4.44797 11.128 4.77996 11.11 5.08504 11.1818Z"
        fill="white"
      />
      <path
        d="M18.5083 14.9324C18.6668 14.9324 18.7953 14.8039 18.7953 14.6454C18.7953 14.4869 18.6668 14.3583 18.5083 14.3583C18.3498 14.3583 18.2213 14.4869 18.2213 14.6454C18.2213 14.8039 18.3498 14.9324 18.5083 14.9324Z"
        fill="url(#paint22_radial_193_4475)"
      />
      <path
        d="M15.5471 3.45629C15.7056 3.45629 15.8342 3.32777 15.8342 3.16924C15.8342 3.01072 15.7056 2.8822 15.5471 2.8822C15.3886 2.8822 15.2601 3.01072 15.2601 3.16924C15.2601 3.32777 15.3886 3.45629 15.5471 3.45629Z"
        fill="url(#paint23_radial_193_4475)"
      />
      <path
        d="M10.5584 13.2903C10.7169 13.2903 10.8454 13.1618 10.8454 13.0033C10.8454 12.8448 10.7169 12.7162 10.5584 12.7162C10.3999 12.7162 10.2714 12.8448 10.2714 13.0033C10.2714 13.1618 10.3999 13.2903 10.5584 13.2903Z"
        fill="url(#paint24_radial_193_4475)"
      />
      <path
        d="M16.7497 26.1933C16.9083 26.1933 17.0368 26.0647 17.0368 25.9062C17.0368 25.7477 16.9083 25.6192 16.7497 25.6192C16.5912 25.6192 16.4627 25.7477 16.4627 25.9062C16.4627 26.0647 16.5912 26.1933 16.7497 26.1933Z"
        fill="url(#paint25_radial_193_4475)"
      />
      <path
        d="M25.7044 10.0872C25.8629 10.0872 25.9914 9.95864 25.9914 9.80011C25.9914 9.64158 25.8629 9.51307 25.7044 9.51307C25.5459 9.51307 25.4174 9.64158 25.4174 9.80011C25.4174 9.95864 25.5459 10.0872 25.7044 10.0872Z"
        fill="url(#paint26_radial_193_4475)"
      />
      <path
        d="M6.06305 20.4148C6.22158 20.4148 6.35009 20.2863 6.35009 20.1278C6.35009 19.9692 6.22158 19.8407 6.06305 19.8407C5.90452 19.8407 5.776 19.9692 5.776 20.1278C5.776 20.2863 5.90452 20.4148 6.06305 20.4148Z"
        fill="url(#paint27_radial_193_4475)"
      />
      <path
        d="M12.9092 7.60163C13.0677 7.60163 13.1962 7.47311 13.1962 7.31458C13.1962 7.15606 13.0677 7.02754 12.9092 7.02754C12.7506 7.02754 12.6221 7.15606 12.6221 7.31458C12.6221 7.47311 12.7506 7.60163 12.9092 7.60163Z"
        fill="url(#paint28_radial_193_4475)"
      />
      <path
        d="M12.9092 24.1116C13.0677 24.1116 13.1962 23.9831 13.1962 23.8246C13.1962 23.666 13.0677 23.5375 12.9092 23.5375C12.7506 23.5375 12.6221 23.666 12.6221 23.8246C12.6221 23.9831 12.7506 24.1116 12.9092 24.1116Z"
        fill="url(#paint29_radial_193_4475)"
      />
      <path
        d="M24.7443 19.8495C24.9028 19.8495 25.0313 19.7209 25.0313 19.5624C25.0313 19.4039 24.9028 19.2754 24.7443 19.2754C24.5857 19.2754 24.4572 19.4039 24.4572 19.5624C24.4572 19.7209 24.5857 19.8495 24.7443 19.8495Z"
        fill="url(#paint30_radial_193_4475)"
      />
      <path
        d="M21.1641 11.7651C21.3226 11.7651 21.4511 11.6366 21.4511 11.4781C21.4511 11.3195 21.3226 11.191 21.1641 11.191C21.0055 11.191 20.877 11.3195 20.877 11.4781C20.877 11.6366 21.0055 11.7651 21.1641 11.7651Z"
        fill="url(#paint31_radial_193_4475)"
      />
      <path
        d="M22.4382 4.13831C22.5968 4.13831 22.7253 4.0098 22.7253 3.85127C22.7253 3.69274 22.5968 3.56422 22.4382 3.56422C22.2797 3.56422 22.1512 3.69274 22.1512 3.85127C22.1512 4.0098 22.2797 4.13831 22.4382 4.13831Z"
        fill="url(#paint32_radial_193_4475)"
      />
      <path
        opacity="0.5"
        d="M8.99704 22.227C9.36364 22.227 9.66083 21.9298 9.66083 21.5632C9.66083 21.1966 9.36364 20.8994 8.99704 20.8994C8.63044 20.8994 8.33325 21.1966 8.33325 21.5632C8.33325 21.9298 8.63044 22.227 8.99704 22.227Z"
        fill="url(#paint33_radial_193_4475)"
      />
      <path
        opacity="0.5"
        d="M8.60226 7.11687C8.96886 7.11687 9.26605 6.81969 9.26605 6.45309C9.26605 6.08649 8.96886 5.7893 8.60226 5.7893C8.23566 5.7893 7.93848 6.08649 7.93848 6.45309C7.93848 6.81969 8.23566 7.11687 8.60226 7.11687Z"
        fill="url(#paint34_radial_193_4475)"
      />
      <path
        opacity="0.5"
        d="M15.3408 10.9572C15.7074 10.9572 16.0046 10.66 16.0046 10.2934C16.0046 9.92682 15.7074 9.62963 15.3408 9.62963C14.9742 9.62963 14.677 9.92682 14.677 10.2934C14.677 10.66 14.9742 10.9572 15.3408 10.9572Z"
        fill="url(#paint35_radial_193_4475)"
      />
      <path
        opacity="0.5"
        d="M25.4173 15.2102C25.7839 15.2102 26.0811 14.913 26.0811 14.5464C26.0811 14.1798 25.7839 13.8826 25.4173 13.8826C25.0507 13.8826 24.7535 14.1798 24.7535 14.5464C24.7535 14.913 25.0507 15.2102 25.4173 15.2102Z"
        fill="url(#paint36_radial_193_4475)"
      />
      <path
        opacity="0.5"
        d="M21.4691 22.9539C21.8357 22.9539 22.1329 22.6567 22.1329 22.2901C22.1329 21.9235 21.8357 21.6263 21.4691 21.6263C21.1025 21.6263 20.8054 21.9235 20.8054 22.2901C20.8054 22.6567 21.1025 22.9539 21.4691 22.9539Z"
        fill="url(#paint37_radial_193_4475)"
      />
      <path
        opacity="0.5"
        d="M3.24545 16.2961C3.61205 16.2961 3.90924 15.9989 3.90924 15.6323C3.90924 15.2657 3.61205 14.9685 3.24545 14.9685C2.87885 14.9685 2.58167 15.2657 2.58167 15.6323C2.58167 15.9989 2.87885 16.2961 3.24545 16.2961Z"
        fill="url(#paint38_radial_193_4475)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_193_4475"
          x1="4.97081"
          y1="28.4102"
          x2="34.5739"
          y2="26.4069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFBC47" />
          <stop offset="0.9996" stop-color="#FF8000" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_193_4475"
          x1="12.6774"
          y1="28.3341"
          x2="9.81466"
          y2="27.1827"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDBC2B" stop-opacity="0" />
          <stop offset="0.2611" stop-color="#FCCC37" stop-opacity="0.5328" />
          <stop offset="0.6488" stop-color="#FCCE38" />
          <stop offset="0.7345" stop-color="#FCCC31" stop-opacity="0.756" />
          <stop offset="0.8568" stop-color="#FAC71F" stop-opacity="0.4079" />
          <stop offset="1" stop-color="#F8BE00" stop-opacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.49711 31.5897) rotate(-18.7538) scale(19.686 13.4805)"
        >
          <stop offset="0.3018" stop-color="#C86F34" stop-opacity="0" />
          <stop offset="0.4439" stop-color="#AD5B24" stop-opacity="0.5" />
          <stop offset="0.6318" stop-color="#A3541E" stop-opacity="0.75" />
          <stop offset="0.7729" stop-color="#C86F34" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.0797 3.126) scale(30.0448 30.0448)"
        >
          <stop stop-color="#C76E33" stop-opacity="0.1634" />
          <stop offset="0.9646" stop-color="#AD5B24" stop-opacity="0.5" />
          <stop offset="1" stop-color="#C86F34" stop-opacity="0" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_193_4475"
          x1="14.8775"
          y1="27.8636"
          x2="14.4724"
          y2="24.2576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A3541E" />
          <stop offset="0.9996" stop-color="#643800" />
        </linearGradient>
        <radialGradient
          id="paint5_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.1332 14.0648) scale(15.0975)"
        >
          <stop stop-color="#D1F2FF" />
          <stop offset="0.1112" stop-color="#CBF0FF" />
          <stop offset="0.2664" stop-color="#B8EBFF" />
          <stop offset="0.4474" stop-color="#9BE2FF" />
          <stop offset="0.6479" stop-color="#71D6FE" />
          <stop offset="0.8621" stop-color="#3DC6FE" />
          <stop offset="1" stop-color="#17BBFE" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-5.30857 1.22605) scale(30.5987)"
        >
          <stop stop-color="#E100FF" stop-opacity="0" />
          <stop offset="0.1148" stop-color="#DB00FD" stop-opacity="0.08613" />
          <stop offset="0.2751" stop-color="#C800F7" stop-opacity="0.2063" />
          <stop offset="0.4621" stop-color="#AB00EC" stop-opacity="0.3466" />
          <stop offset="0.6692" stop-color="#8100DE" stop-opacity="0.5019" />
          <stop offset="0.8904" stop-color="#4D00CC" stop-opacity="0.6678" />
          <stop offset="1" stop-color="#3000C2" stop-opacity="0.75" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.944 9.76049) scale(19.4986)"
        >
          <stop stop-color="#D1F2FF" />
          <stop offset="0.1913" stop-color="#CBF0FF" stop-opacity="0.9522" />
          <stop offset="0.458" stop-color="#B8EBFF" stop-opacity="0.8855" />
          <stop offset="0.7676" stop-color="#9BE2FE" stop-opacity="0.8081" />
          <stop offset="1" stop-color="#80DAFE" stop-opacity="0.75" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.3763 14.0277) scale(14.3045)"
        >
          <stop offset="0.873" stop-color="#3640FF" stop-opacity="0" />
          <stop offset="0.9013" stop-color="#353AF9" stop-opacity="0.1673" />
          <stop offset="0.9408" stop-color="#3427E8" stop-opacity="0.4006" />
          <stop offset="0.9867" stop-color="#310ACB" stop-opacity="0.6715" />
          <stop offset="1" stop-color="#3000C2" stop-opacity="0.75" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_193_4475"
          x1="9.02575"
          y1="18.5946"
          x2="33.5901"
          y2="1.89675"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0322279" stop-color="#80DAFE" stop-opacity="0" />
          <stop offset="1" stop-color="#9F00E8" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_193_4475"
          x1="4.17056"
          y1="2.81676"
          x2="45.6373"
          y2="40.5912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#80DAFE" stop-opacity="0" />
          <stop offset="1" stop-color="#008EE6" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_193_4475"
          x1="17.2534"
          y1="17.8096"
          x2="27.2721"
          y2="30.2773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B64AE8" stop-opacity="0" />
          <stop offset="1" stop-color="#7000A3" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_193_4475"
          x1="16.2098"
          y1="14.9235"
          x2="32.9447"
          y2="3.94007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#80DAFE" stop-opacity="0" />
          <stop offset="1" stop-color="#008EE6" />
        </linearGradient>
        <radialGradient
          id="paint13_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.9063 7.30705) rotate(-33.2499) scale(6.39673)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1.92885 1.73233) scale(31.2131 31.2131)"
        >
          <stop stop-color="#FF7AD3" />
          <stop offset="0.9987" stop-color="#3000C2" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.4988 -1.44561) scale(22.8426 22.8426)"
        >
          <stop stop-color="#7000A3" />
          <stop offset="1" stop-color="#B64AE8" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.04312 25.1099) scale(0.14174 0.141736)"
        >
          <stop stop-color="#FB9DC1" />
          <stop offset="0.1456" stop-color="#F5A0C4" stop-opacity="0.8544" />
          <stop offset="0.3488" stop-color="#E2A9CD" stop-opacity="0.6512" />
          <stop offset="0.5857" stop-color="#C5B8DC" stop-opacity="0.4143" />
          <stop offset="0.8461" stop-color="#9CCCF0" stop-opacity="0.1539" />
          <stop offset="1" stop-color="#80DAFE" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.81156 24.9206) scale(0.14217 0.142169)"
        >
          <stop stop-color="#FB9DC1" />
          <stop offset="0.1456" stop-color="#F5A0C4" stop-opacity="0.8544" />
          <stop offset="0.3488" stop-color="#E2A9CD" stop-opacity="0.6512" />
          <stop offset="0.5857" stop-color="#C5B8DC" stop-opacity="0.4143" />
          <stop offset="0.8461" stop-color="#9CCCF0" stop-opacity="0.1539" />
          <stop offset="1" stop-color="#80DAFE" stop-opacity="0" />
        </radialGradient>
        <linearGradient
          id="paint18_linear_193_4475"
          x1="4.40234"
          y1="8.80783"
          x2="39.633"
          y2="37.6936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#80DAFE" stop-opacity="0" />
          <stop offset="1" stop-color="#008EE6" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_193_4475"
          x1="8.81398"
          y1="12.5942"
          x2="1.09589"
          y2="39.8302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#80DAFE" stop-opacity="0" />
          <stop offset="1" stop-color="#008EE6" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_193_4475"
          x1="17.3048"
          y1="15.6704"
          x2="38.9006"
          y2="26.1901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#80DAFE" stop-opacity="0" />
          <stop offset="1" stop-color="#008EE6" />
        </linearGradient>
        <radialGradient
          id="paint21_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.7288 8.79573) scale(21.5989)"
        >
          <stop stop-color="#E100FF" stop-opacity="0" />
          <stop offset="0.1148" stop-color="#DB00FD" stop-opacity="0.08613" />
          <stop offset="0.2751" stop-color="#C800F7" stop-opacity="0.2063" />
          <stop offset="0.4621" stop-color="#AB00EC" stop-opacity="0.3466" />
          <stop offset="0.6692" stop-color="#8100DE" stop-opacity="0.5019" />
          <stop offset="0.8904" stop-color="#4D00CC" stop-opacity="0.6678" />
          <stop offset="1" stop-color="#3000C2" stop-opacity="0.75" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.5041 14.6419) scale(0.293142)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.5477 3.16432) scale(0.293142)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.5544 12.9948) scale(0.293141)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.7527 25.8988) scale(0.293137 0.293144)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.7016 9.79229) scale(0.29314 0.293142)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.06296 20.1223) scale(0.293146)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.9061 7.30541) scale(0.293141)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.9061 23.8222) scale(0.293141 0.293143)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.7474 19.5605) scale(0.293142 0.29314)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.1682 11.4762) scale(0.293142 0.293144)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.4342 3.84408) scale(0.293143)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.99581 21.546) scale(0.684007 0.684009)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.60622 6.43486) scale(0.684003 0.684005)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.341 10.2794) scale(0.684006)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.4154 14.5344) scale(0.684005 0.684006)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.4704 22.279) scale(0.684009 0.684004)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_193_4475"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.24256 15.6142) scale(0.684006 0.684009)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
