
const Router = {
  fetchPostWithData: function(path, data, onResponse){
    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key]);
    }

    fetch(path, {
      method: 'POST', headers: {
        'Accept': 'text/html',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }, body: form_data
    }).then(
      response => response.json()
    ).then(res_data => {
      onResponse(res_data)
    })
  },

  fetchWithData: function(path, data, method, onResponse = null){
    const form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key]);
    }

    fetch(path, {
      method: method, headers: {
        'Accept': 'text/html',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }, body: form_data
    }).then(
      response => response.json()
    ).then(res_data => {
      onResponse(res_data)
    })
  },

  fetchGetRequest: function(path, onResponse = null) {
    fetch(path, {
      method: 'GET', headers: {
        'Accept': 'text/html',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    }).then(
      response => response.json()
    ).then(res_data => {
      onResponse(res_data)
    })
  },

  fetchDeleteRequest: function(path, onResponse = null){
    fetch(path, {
      method: 'DELETE', headers: {
        'Accept': 'text/html',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    }).then(
      response => response.json()
    ).then(res_data => {
      onResponse(res_data)
    })
  }
}

export default Router
