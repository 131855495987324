import React from "react";
import Link from "@components/menu/Link";
import Analytics from "@components/icons/sub_menu/services/Analytics";
import Promotion from "@components/icons/sub_menu/services/Promotion";
import LogoUpload from "@components/icons/sub_menu/services/LogoUpload";
import MainBtn from "@components/icons/sub_menu/services/MainBtn";
import NFC from "@components/icons/sub_menu/services/NFC";
import PersonalLink from "@components/icons/sub_menu/services/PersonalLink";
import Design from "@components/icons/sub_menu/services/Design";
import Reviews from "@components/icons/sub_menu/services/Reviews";
import CRM from "@components/icons/sub_menu/services/CRM";

const Services = ({ changeSecondStep }) => {
  const menu_items = [
    {
      href: "/views",
      text: I18n.t("sub_menu.analytics"),
      className: "sub_nav-container-body-links-links_with_big_icon",
      iconClassName: "icon analytics",
      icon: <Analytics />,
      react_icon: true,
    },
    {
      href: "#promotion",
      text: I18n.t("sub_menu.promotion"),
      className: "sub_nav-container-body-links-links_with_big_icon",
      iconClassName: "icon promotion",
      icon: <Promotion />,
      react_icon: true,
      onClick: () =>
        changeSecondStep(false, "promotion", I18n.t("sub_menu.promotion")),
    },
    // {
    //   href: "#design",
    //   text: I18n.t("sub_menu.design"),
    //   className: "sub_nav-container-body-links-links_with_big_icon",
    //   iconClassName: "icon design",
    //   icon: <Design />,
    //   react_icon: true,
    // },
    {
      href: "#main_btn",
      text: I18n.t("sub_menu.main_btn"),
      className: "sub_nav-container-body-links-links_with_big_icon",
      iconClassName: "icon main-btn",
      icon: <MainBtn />,
      react_icon: true,
      onClick: () =>
        changeSecondStep(false, "main_btn", I18n.t("sub_menu.main_btn")),
    },
    {
      href: "#personal_link",
      text: I18n.t("sub_menu.your_link"),
      className: "sub_nav-container-body-links-links_with_big_icon",
      iconClassName: "icon personal_link",
      icon: <PersonalLink />,
      react_icon: true,
      onClick: () =>
        changeSecondStep(false, "personal_link", I18n.t("sub_menu.your_link")),
    },
    {
      href: "#logo_upload",
      text: I18n.t("sub_menu.logo_upload"),
      className: "sub_nav-container-body-links-links_with_big_icon",
      iconClassName: "icon logo_upload",
      icon: <LogoUpload />,
      react_icon: true,
      onClick: () =>
        changeSecondStep(false, "logo_upload", I18n.t("sub_menu.logo_upload")),
    },
    // {
    //   href: "#reviews",
    //   text: I18n.t("sub_menu.reviews"),
    //   className: "sub_nav-container-body-links-links_with_big_icon",
    //   iconClassName: "icon",
    //   icon: <Reviews />,
    //   react_icon: true,
    // },
    // {
    //   href: "#crm",
    //   text: I18n.t("sub_menu.crm"),
    //   className: "sub_nav-container-body-links-links_with_big_icon",
    //   iconClassName: "icon",
    //   icon: <CRM />,
    //   react_icon: true,
    // },
    {
      href: "http://yourcf.ru/?utm_source=yourcf&utm_medium=online&utm_campaign=service-nfc",
      text: I18n.t("sub_menu.nfc"),
      className: "sub_nav-container-body-links-links_with_big_icon",
      iconClassName: "icon",
      icon: <NFC />,
      react_icon: true,
    },
  ].map((opt) => <Link key={opt.href} {...opt} />);

  return (
    <nav className="container px-4 grid grid-cols-3  sub_nav-container-body-links"> {/*gap-8*/}
      {menu_items}
    </nav>
  );
};

export default Services;
