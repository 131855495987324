import React from 'react';

export default props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="none" viewBox="0 0 63 63">
      <rect width="63" height="63" fill="#ECEBED" rx="16"/>
      <path fill="#D0D0D0" d="M32.578 51.798c0-2.666 0-3.998.827-4.824.828-.826 2.16-.83 4.824-.83h.472c2.664 0 3.998 0 4.824.83.825.829.829 2.165.829 4.824v.47c0 2.665 0 3.997-.828 4.825-.827.827-2.16.827-4.823.827h-.47c-2.664 0-3.999 0-4.824-.827-.826-.828-.827-2.16-.827-4.824l-.004-.471ZM18 51.797c0-2.664 0-3.998.779-4.825.778-.827 2.034-.827 4.541-.827h.443c2.507 0 3.762 0 4.541.827.779.827.779 2.161.779 4.825v.47c0 2.665 0 3.998-.779 4.824-.779.826-2.034.83-4.541.83h-.443c-2.507 0-3.763 0-4.541-.83C18 56.262 18 54.931 18 52.268v-.47Zm0-16.145c0-2.664 0-3.996.827-4.825.827-.829 2.16-.827 4.824-.827h.474c2.663 0 3.997 0 4.823.827.828.827.828 2.16.828 4.825v.472c0 2.664 0 3.997-.828 4.824-.826.828-2.16.828-4.823.828h-.47c-2.664 0-3.998 0-4.825-.828-.827-.827-.827-2.159-.827-4.824L18 35.652Zm26.336-3.114a2.966 2.966 0 0 0-.238-1.073 2.433 2.433 0 0 0-.702-.92 2.248 2.248 0 0 0-1.026-.466 6.054 6.054 0 0 0-1.091-.078h-5.08a4.881 4.881 0 0 0-1.051.1c-.486.11-.925.385-1.252.782a2.55 2.55 0 0 0-.577 1.405 9.467 9.467 0 0 0-.047 1.075v5.331a5.05 5.05 0 0 0 .132 1.25c.09.338.25.65.468.915a2.3 2.3 0 0 0 1.568.866c.14.016.279.03.419.037.016 0 .034 0 .045.014h5.802a1.21 1.21 0 0 1 .25-.027c.315-.017.625-.086.92-.206.407-.186.758-.49 1.012-.875.255-.386.404-.84.43-1.31.01-.112.016-.227.024-.346 0-.016 0-.032.012-.042v-6.155a1.369 1.369 0 0 1-.018-.277Z"/>
      <path fill="#4479FA" d="M46.862 14H15.138C12.3 14 10 16.239 10 19s2.3 5 5.138 5h31.724C49.699 24 52 21.761 52 19s-2.3-5-5.138-5Z"/>
    </svg>

  );
};


