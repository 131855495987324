import React from "react";

const Info = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="18.5008" height="18.5" rx="9.25" stroke="#A5A2A7" strokeWidth="1.5"/>
      <path d="M7.07303 16.15V15.3863C7.10812 15.3863 7.14402 15.3789 7.17992 15.3781C7.43614 15.3724 7.69318 15.3691 7.9494 15.361C8.38433 15.3471 8.58343 15.1406 8.58425 14.7041C8.58425 14.0159 8.58425 13.3283 8.58425 12.6413V10.106C8.58425 9.68899 8.42105 9.48173 8.00571 9.42624C7.76465 9.4024 7.52252 9.39123 7.28029 9.39278C7.16524 9.39278 7.05018 9.39278 6.92941 9.39278V8.63799L11.6377 8.46826V14.7514C11.6377 15.139 11.7748 15.3022 12.164 15.3397C12.4251 15.365 12.6895 15.3659 12.9523 15.3773L13.0681 15.3838V16.15H7.07303Z" fill="#A5A2A7"/>
      <path d="M8.08328 5.5019C8.09724 5.16107 8.21188 4.83202 8.41274 4.55631C8.6136 4.28059 8.89165 4.07059 9.2118 3.95281C9.53194 3.83503 9.8798 3.81477 10.2114 3.89458C10.5431 3.97439 10.8437 4.15069 11.0752 4.40122C11.3067 4.65175 11.4588 4.96526 11.5122 5.30217C11.5656 5.63907 11.518 5.98426 11.3754 6.29413C11.2328 6.60399 11.0015 6.86464 10.7108 7.04315C10.4201 7.22166 10.0831 7.31003 9.7422 7.29709C9.51498 7.28944 9.29154 7.23678 9.08481 7.14217C8.87809 7.04755 8.6922 6.91285 8.5379 6.74588C8.3836 6.57891 8.26397 6.38298 8.18594 6.16945C8.1079 5.95592 8.07301 5.72902 8.08328 5.5019Z" fill="#A5A2A7"/>
    </svg>
  )
}

export default Info;
