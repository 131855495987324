import React from "react";

const GoPro = () => {
  return (
    <nav className="container px-4 sub_nav-container-body-links">
      <div className='go_pro'>
        <div className='go_pro__txt'>
          {I18n.t("sub_menu.go_pro_txt.title")}
        </div>

        <a href='/plan' className='go_pro__btn'>
          {I18n.t("sub_menu.go_pro_txt.button")}
        </a>
      </div>
    </nav>
  )
}

export default GoPro;
