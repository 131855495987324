import React from "react";
import Router from "@components/router.js";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import infoIcon from "@images/info-icon.jpg";
import InlineSvg from "@components/InlineSvg";
import burger from "@images/svgs/burger.svg";

const CardSettings = ({ profile, profiles, max_length_profiles }) => {
  const { register, setValue, getValues } = useForm();
  const { email, lang, access_by_link, visibility_search_engines } = profile;
  const profiles_length = profiles.length;

  register("email", { value: email });
  register("password", { value: "" });
  register("access_by_link", { value: access_by_link });
  register("visibility_search_engines", { value: visibility_search_engines });
  register("lang", { value: lang || "ru" });

  const createNewProfile = () => {
    const onResponce = (res_data) => {
      if (res_data.success) {
        document.location.reload();
      } else {
        toast.error(I18n.t("notify.error"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    };
    Router.fetchWithData("/profile", {}, "POST", onResponce);
  };

  const deleteProfile = () => {
    const onResponce = (res_data) => {
      if (res_data.success) {
        document.location.reload();
      } else {
        console.log("error");
        console.log(res_data);
      }
    };
    Router.fetchWithData("/profile", {}, "DELETE", onResponce);
  };

  const onSubmit = () => {
    const path = "/accounts";
    const data = getValues();
    console.log(data);
    const method = "PATCH";
    const onResponse = (res_data) => {
      if (res_data.success) {
        toast.success(I18n.t("notify.success"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      } else {
        toast.error(I18n.t("notify.error"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          zIndex: 9999,
        });
      }
    };

    Router.fetchWithData(path, data, method, onResponse);
  };

  const onSubmitSelect = () => {
    setTimeout(() => {
      window.location.href = window.location.pathname;
    }, 5000);
  };

  return (
    <nav className="container px-4 sub_nav-container-body-links">
      <form className="form_v1">
        <div className="form_v1-group">
          <label className="form_v1-group-label">
            <div>{I18n.t("form.access_by_link")}</div>
          </label>
          <div className="flex flex-row items-center">
            <div className="form_v1-group-input_block">
              <div className="form_v1-group-input_block-input">
                {I18n.t("form.status")}
              </div>
              <span className="form_v1-group-input_block-action">
                <label className="form_v1-group-input_block-switch">
                  <input
                    type="checkbox"
                    defaultChecked={access_by_link}
                    onChange={(e) => {
                      setValue("access_by_link", e.target.checked)
                      onSubmit();
                    }}
                  />
                  <i></i>
                </label>
              </span>
            </div>
            <div className="info">
              <div>
                <div data-tip data-for="access_by_link_info">
                  <img src={infoIcon} width={22} height={22} />
                </div>
                <ReactTooltip
                  id="access_by_link_info"
                  place="bottom"
                  type="dark"
                  effect="solid"
                  className="custom_tooltip"
                >
                  {I18n.t("form.access_by_link_info")}
                </ReactTooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="form_v1-group">
          <label className="form_v1-group-label">
            <div>{I18n.t("form.visibility_search_engines")}</div>
          </label>
          <div className="flex flex-row items-center">
            <div className="form_v1-group-input_block">
            <div className="form_v1-group-input_block-input">
              {" "}
              {I18n.t("form.status")}{" "}
            </div>
            <span className="form_v1-group-input_block-action">
              <label className="form_v1-group-input_block-switch">
                <input
                  type="checkbox"
                  defaultChecked={visibility_search_engines}
                  onChange={(e) => {
                    setValue("visibility_search_engines", e.target.checked),
                      onSubmit();
                  }}
                />
                <i></i>
              </label>
            </span>
          </div>
            <div className="info">
              <div>
                <div data-tip data-for="visibility_search_engines_info">
                  <InlineSvg src={infoIcon} />
                </div>
                <ReactTooltip
                  id="visibility_search_engines_info"
                  place="bottom"
                  type="dark"
                  effect="solid"
                  className="custom_tooltip"
                  multiline={true}
                >
                  {I18n.t("form.visibility_search_engines_info")}
                </ReactTooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="form_v1-group">
          <label className="form_v1-group-label">{I18n.t("form.lang")}</label>
          <div className="flex flex-row items-center">
            <div className="form_v1-group-input_block">
            <select
              className="form_v1-group-input_block-input black-color"
              name="email"
              defaultValue={lang || "ru"}
              onChange={(e) => setValue("lang", e.target.value)}
            >
              <option value="ru">{I18n.t("lang.ru")}</option>
              <option value="en">{I18n.t("lang.en")}</option>
            </select>

            <span
              className="form_v1-group-input_block-action"
              onClick={() => {
                onSubmit(), onSubmitSelect();
              }}
            >
              {" "}
              {I18n.t("form.edit")}
            </span>
          </div>
            <div className="info">
              <div>
                <div data-tip data-for="lang_info">
                  <InlineSvg src={infoIcon} />
                </div>
                <ReactTooltip
                  id="lang_info"
                  place="bottom"
                  type="dark"
                  effect="solid"
                  className="custom_tooltip"
                  multiline={true}
                >
                  {I18n.t("form.lang_info")}
                </ReactTooltip>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="form_v1-actions">
        {profiles_length < max_length_profiles && (
          <button
            className="form_v1-actions_success"
            onClick={() => createNewProfile()}
          >
            {I18n.t("sub_menu.cards_text.short_new_button")}
          </button>
        )}

        {profiles_length > 1 && (
          <button
            className="form_v1-actions_delete"
            onClick={() => deleteProfile()}
          >
            {I18n.t("sub_menu.cards_text.destroy_button")}
          </button>
        )}
      </div>
    </nav>
  );
};

export default CardSettings;
