import React from 'react';
import InlineSvg from '@components/InlineSvg';
import Link from "@components/menu/Link";

import logo from "@images/svgs/sub_menu/app/logo";
import apple_plus from "@images/svgs/sub_menu/app/apple_plus";
import apple_share from "@images/svgs/sub_menu/app/apple_share";

const App = () => {
  return (
    <div className='container application_sub'>
        <div className='application_sub-header'>{I18n.t('sub_menu.app_text.install')} <br/> {I18n.t('sub_menu.app_text.install_2')}</div>
        <div className='application_sub-body'>
          <InlineSvg className="application_sub-body-icon" src={logo}/>
          <div className='application_sub-body-text_cont'>
            <div className='text_cont-share'>
              <div className='text_cont-with_icon'> {I18n.t('sub_menu.app_text.touch')} <InlineSvg className="icon-apple_share" src={apple_share}/> {I18n.t('sub_menu.app_text.touch_2')}  </div>
              <div> {I18n.t('sub_menu.app_text.touch_3')} </div>
            </div>
            <div className='text_cont-add_home'>
              {I18n.t('sub_menu.app_text.home')} <InlineSvg className="icon-apple_plus" src={apple_plus}/>
            </div>
          </div>
        </div>
        <div className='application_sub-footer'>
          <div className='application_sub-footer_body'>
            <Link className="link" href="https://www.yourcf.ru/app#android" target={true} text={I18n.t('sub_menu.app_text.android')} />
          </div>
        </div>

    </div>
  )
}

export default App;
