import React, {useState} from "react";
import Standart from "./Standart";
import Pro from "./Pro";

const Price = ({activeTab, rateData}) => {
  return (
    <div>
      <Standart />
      <Pro rateData={rateData} activeTab={activeTab} />
    </div>
  )
}

export default Price;
