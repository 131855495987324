import React from 'react';

const InlineSvg = ({src, src_name, react_svg, className, style, onClick}) => {
    
    return (
        <span className={className} style={style} onClick={onClick}>
          {src_name &&
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={`svg-icon`} style={{fill: "currentColor"}} >
              <use xlinkHref={`${src}#${src_name}`} />
            </svg>
          }
          {!src_name && !react_svg && <img src={src}/>}
          {react_svg && src}
        </span>
    )
};

export default InlineSvg;
