import React, {useState} from "react";
import Price from "../Price/Price";
import Period from "../Period/Period";

const Rate = ({rateData}) => {
  const [activeTab, setActiveTab] = useState('one_month');

  return (
    <div style={{'marginTop': '70px'}}>
      <Period rateData={rateData} activeTab={activeTab} setActiveTab={setActiveTab}/>
      <Price rateData={rateData} activeTab={activeTab} />
    </div>
  )
}

export default Rate;
