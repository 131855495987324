import React from "react";
import Router from "@components/router.js";
import userIcon from "@images/user_icon.png";
import MagicBall from "@components/icons/sub_menu/cards/MagicBall";
import {toast} from "react-toastify";

const createNewProfile = () => {
  const onResponce = (res_data) => {
    if (res_data.success) {
      document.location.reload();
    } else {
      toast.error(I18n.t("notify.error"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        zIndex: 9999,
      });
    }
  };
  Router.fetchWithData("/profile", {}, "POST", onResponce);
};

const switchProfile = (id) => {
  const onResponce = (res_data) => {
    if (res_data.success) {
      document.location.reload();
    } else {
      console.log("error");
      console.log(res_data);
    }
  };
  Router.fetchWithData("/profile/switch", { id: id }, "PATCH", onResponce);
};

const Cards = ({ profiles, max_length_profiles }) => {
  return (
    <nav className="container px-4 sub_nav-container-body-cards">
      <div className="cards">
        {profiles &&
          profiles.map((profile) => (
            <div
              className={`cards-card ${profile.current && "current"}`}
              onClick={() => switchProfile(profile.id)}
            >
              <div
                className="cards-card_avatar rounded-full"
                style={{
                  backgroundImage: `url(${profile.avatar_url || userIcon}`,
                }}
              ></div>
              <div className="cards-card_body">
                <div className="cards-card_body-labels">
                  {profile.access_by_link && (
                    <div className="cards-card_body-labels_access_link">
                      {I18n.t("sub_menu.cards_text.access_by_link")}
                    </div>
                  )}
                  {profile.visibility_search_engines && (
                    <div className="cards-card_body-labels_public_profile">
                      {I18n.t("sub_menu.cards_text.public_profile")}
                    </div>
                  )}
                </div>
                <div className="cards-card_body-full_name">
                  {profile.full_name}
                </div>
                <div className="cards-card_body-full_company">
                  {profile.company}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="cards-card_pro">
        <div className="cards-card_pro_avatar rounded-full">
          <MagicBall />
        </div>
        <div className="cards-card_pro_body">
          <div className="cards-card_pro_body-title">
            {I18n.t("sub_menu.cards_text.go_to_pro")}
          </div>
          <div className="cards-card_pro_body-desc">
            {I18n.t("sub_menu.cards_text.go_to_pro_desc")}
          </div>
        </div>
      </div>
      {profiles.length < max_length_profiles && (
        <div className="cards-create_new">
          <button onClick={() => createNewProfile()}>
            {I18n.t("sub_menu.cards_text.new_button")}
          </button>
        </div>
      )}
    </nav>
  );
};

export default Cards;
