import React from 'react';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

const Notify = () => {
    
    return (
      <div className='notify'>
        <ToastContainer/>
      </div>
    )
};

export default Notify;
