import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['source']

  send (e) {
    fetch(e.target.dataset.source, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')
      }
    })
    return true
  }
}
