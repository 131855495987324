import React, {useState} from "react";
import circle from '@images/circle.svg'
import arrow_drop_down from '@images/arrow_drop_down.svg'
import pro from '@images/pro.svg'
import Description from "./Description";

const features_paid = [I18n.t("plans.pro.bullet1"), I18n.t("plans.pro.bullet2"), I18n.t("plans.pro.bullet3"), I18n.t("plans.pro.bullet4"), I18n.t("plans.pro.bullet5")]

const Pro = ({standart, activeTab, rateData}) => {
  const [showDeatils, setShowDeatils] = useState(false);

  let period = I18n.t('plans.per_' + activeTab)

  return (
    <div className='pro-block mb-20' onClick={() => setShowDeatils(!showDeatils)}>

      <div className='price-header'>
        <img className='price-header__img' width='48' height='48' src={circle} alt="circle"/>
        <div className='price-header__txt pro'>
          <img className='pro__name' src={pro} width='62' height='30' alt="pro"/>
          <p
            className='pro__price'>
            {rateData.find(i => i.key === activeTab).price + ' ₽ / ' + period}
          </p>
          <p className='pro__details'>{rateData.find(i => i.key === activeTab).details}</p>
        </div>
        <img className='price-header__arrow_drop_down' width='9' height='7' src={arrow_drop_down}
             alt="arrow_drop_down"/>
      </div>

      <Description showDeatils={showDeatils} activeTab={activeTab} features={features_paid} paid={true}/>

    </div>
  )
}


export default Pro;
