import React from "react";
import ReactTooltip from "react-tooltip";
import InlineSvg from "@components/InlineSvg";
import { toast } from "react-toastify";

import Email from "@components/icons/sub_menu/promotion/Email";
import Search from "@components/icons/sub_menu/promotion/Search";
import Social from "@components/icons/sub_menu/promotion/Social";
import ShareIcon from "@components/icons/ShareIcon";
import Copy from "@components/icons/Copy";
import imgFirst from "@images/promotion/1.png";
import imgSecond from "@images/promotion/2.png";
import imgThird from "@images/promotion/3.png";
import infoIcon from "@images/info-icon.jpg";
import userIcon from "@images/user_icon.png";

const Promotion = ({ profile, profile_avatar, changeThirdStep }) => {
  const profile_link = $(location).attr("host") + "/" + profile.slug;

  const tooltipClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(
          `
            ${I18n.t('with_respect')} ${profile.full_name}
            ${I18n.t('my_vc')} - ${profile_link}
          `
        )
        .then(() => {
          toast.success(I18n.t("copied"), {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            zIndex: 9999,
          });
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    } else {
      console.log("Clipboard not supported.");
    }
  };
  return (
    <nav className="container px-4 sub_nav-container-body-promotion">
      <div className="promotion-container">
        <div>
          <div className="promotion-container-header">
            <div
              className="promotion-container-header-box"
              style={{ marginRight: "10px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="promotion-container-header-box-icon">
                  <Email />
                </div>
                <div>{I18n.t("sub_menu.promotions.email")}</div>
              </div>

              <div
                className="promotion-container-header-action"
                onClick={tooltipClipboard}
              >
                <Copy />
              </div>
            </div>
            <div className="info">
              <div>
                <div data-tip data-for="promotion_email">
                  <InlineSvg src={infoIcon} />
                </div>
                <ReactTooltip
                  id="promotion_email"
                  place="bottom"
                  type="dark"
                  effect="solid"
                  className="custom_tooltip"
                >
                  {I18n.t("sub_menu.promotions.email_info_text")}
                </ReactTooltip>
              </div>
            </div>
          </div>
        </div>

        <ul className="promotion-container-skeleton">
          <li style={{ width: "75%" }} />
          <li style={{ width: "75%" }} />
          <li style={{ width: "60%" }} />
          <li style={{ width: "60%" }} />
        </ul>
        <div className="promotion-container-info">
          <div>С уважением,</div>
          <div className="promotion-container-info-box">
            <div
              className="info_img rounded-full"
              style={{ backgroundImage: `url(${profile_avatar || userIcon}` }}
            ></div>
            <div className="promotion-container-info-box_body">
              <div className="body_title">{profile.full_name}</div>
              <div className="body_desc">
                Моя электронная визитка - {profile_link}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="promotion-container">
        <div className="promotion-container-header">
          <div className="promotion-container-header-box">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="promotion-container-header-box-icon">
                <Search />
              </div>
              <div>{I18n.t("sub_menu.promotions.social")}</div>
            </div>
            <div
              className="promotion-container-header-action"
              data-controller="modal"
              data-action="click->modal#open"
            >
              <ShareIcon />
            </div>
          </div>
        </div>
        <div className="promotion-container-info">
          <div className="promotion-container-info-box">
            <div
              className="info_img rounded-full"
              style={{ backgroundImage: `url(${profile_avatar || userIcon}` }}
            ></div>
            <div style={{ width: "100%" }}>
              <ul className="promotion-container-skeleton">
                <li style={{ width: "35%", background: "#343A49" }} />
                <li style={{ width: "50%" }} />
                <li style={{ width: "50%" }} />
                <li
                  style={{
                    background: "none",
                    fontWeight: "700",
                    fontZize: "14px",
                    lineHeight: "17px",
                    color: "#343A49",
                  }}
                >
                  {profile_link}
                </li>
              </ul>
            </div>
          </div>
          <div className="promotion-container-info-imgs">
            <img src={imgFirst} />
            <img src={imgSecond} />
            <img src={imgThird} />
          </div>
        </div>
      </div>
      <div className="promotion-container">
        <div className="promotion-container-header">
          <div className="promotion-container-header-box">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="promotion-container-header-box-icon">
                <Social />
              </div>
              <div>{I18n.t("sub_menu.promotions.search")}</div>
            </div>
            <div
              className="promotion-container-header-action"
              onClick={() =>
                changeThirdStep(false, "card_settings", I18n.t("sub_menu.card_settings"))
              }
            >
              <ShareIcon />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Promotion;
